import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { environment } from "environments/environment";
import { MarketingPortalEditorDialogComponent } from "../../dialogs/marketing-portal/marketing-portal-editor-dialog/marketing-portal-editor-dialog.component";
import { MarketingPortalVideoEditorDialogComponent } from "../../dialogs/marketing-portal/marketing-portal-video-editor-dialog/marketing-portal-video-editor-dialog.component";
import { PresalesItem } from "app/model/presales/PresalesItem";
import { AppConfirmService } from "app/shared/services/app-confirm/app-confirm.service";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { PresalesItemExpandedDialogComponent } from "../../dialogs/presales-item-expanded-dialog/presales-item-expanded-dialog.component";
import { ApprovalElementCommentDialogComponent } from "../../dialogs/approval-element-comment-dialog/approval-element-comment-dialog.component";
import { MPConfiguratorCreationType } from "app/model/marketing-portal/MPConfiguratorCreationType";

@Component({
  selector: "ma-presales-creative-card",
  templateUrl: "./presales-creative-card.component.html",
})
export class PresalesCreativeCardComponent implements OnInit {
  @Output() openPreview: EventEmitter<any> = new EventEmitter();
  @Output() reload: EventEmitter<any> = new EventEmitter();
  @Input() presalesItem: PresalesItem;
  @Input() customerId: number;
  @Input() isVideo: boolean;

  public environment = environment;
  public mediaAssetIndex: number;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private confirmService: AppConfirmService,
    private appLoader: AppLoaderService,
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.mediaAssetIndex = 0;
  }

  next() {
    if (this.mediaAssetIndex >= this.presalesItem.mediaAssets.length - 1) {
      this.mediaAssetIndex = 0;
      return;
    }

    this.mediaAssetIndex++;
  }

  back() {
    if (this.mediaAssetIndex === 0) {
      this.mediaAssetIndex = this.presalesItem.mediaAssets.length - 1;
      return;
    }

    this.mediaAssetIndex--;
  }

  async rerenderMediaAsset() {
    this.appLoader.open();

    const mediaAssetIds = [
      this.presalesItem.mediaAssets[this.mediaAssetIndex].mediaAssetId,
    ];

    await this.crudService
      .regenerateMediaAssets(
        this.presalesItem.channelId,
        this.customerId,
        mediaAssetIds
      )
      .toPromise()
      .then((res: any) => {
        this.updateMediaAsset(mediaAssetIds[0], res[0]);
        this.snackbarService.show(
          "Das Werbemittel wurde erfolgreich neu erstellt",
          "success"
        );
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.appLoader.close();
  }

  createNewCreative() {
    let mediaAssetIds: string = this.presalesItem.mediaAssets
      .map((mediaAsset) => mediaAsset.mediaAssetId)
      .join(",");
    let preselectedChannelId: number = this.presalesItem.channelId;
    let path = `/mapAds/marketing-portal/configurator?creationType=${MPConfiguratorCreationType.CAMPAIGN}&returnUrl=${this.router.url}&customerId=${this.customerId}&mediaAssetIds=${mediaAssetIds}&preselectChannel=${preselectedChannelId}`;
    this.router.navigateByUrl(path, { replaceUrl: true });
  }

  edit() {
    const mediaAsset: MPMediaAsset =
      this.presalesItem.mediaAssets[this.mediaAssetIndex];

    this.openEditor(mediaAsset, true);
  }

  openEditor(mediaAsset: MPMediaAsset, edit: boolean) {
    const dialogComponent = mediaAsset.isImage
      ? MarketingPortalEditorDialogComponent
      : MarketingPortalVideoEditorDialogComponent;

    const dialogRef = this.dialog.open(dialogComponent, {
      width: "100vw",
      height: "auto",
      maxWidth: "100vw",
      maxHeight: "auto",
      panelClass: "full-screen-dialog",
      data: {
        customerId: this.customerId,
        mediaAsset: mediaAsset,
        isEdit: edit,
        channelId: null,
        resolutionId: null,
        preSelectTemplateId: null,
      },
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        const newMediaAssetId = mediaAsset.isImage
          ? res.templateMediaAssets.templateMediaAssets[0].imageId
          : res.templateMediaAsset.mediaAssetId;
        this.updateMediaAsset(mediaAsset.mediaAssetId, newMediaAssetId);
      }
    });
  }

  updateMediaAsset(oldMediaAssetId: number, newMediaAssetId: number) {
    const index = this.presalesItem.mediaAssets.findIndex(
      (mediaAsset) => mediaAsset.mediaAssetId === oldMediaAssetId
    );

    if (index === -1) return;

    const imagePath =
      environment.imageServerUrl +
      "api/Public/GetMediaAsset?id=" +
      newMediaAssetId;

    this.presalesItem.mediaAssets[index].mediaAssetId = newMediaAssetId;
    this.presalesItem.mediaAssets[index].mediaAssetUrl = imagePath;
    this.presalesItem.mediaAssets[index].mediaAssetThumbnailUrl =
      imagePath + "&width=300&height=300";
  }

  onDelete() {
    this.confirmService
      .confirm({
        title: "Bitte bestätige Deine Aktion",
        message:
          "Bist du sicher, dass Du das Werbemittel aus dem Presales-Ordner entfernen willst?",
      })
      .subscribe(async (res) => {
        if (res) {
          this.appLoader.open();

          await this.crudService
            .deleteItemFromPresales(
              this.presalesItem.mediaAssets[this.mediaAssetIndex].mediaAssetId,
              this.presalesItem.channelId,
              this.customerId
            )
            .toPromise()
            .then((res) => {
              this.snackbarService.show(
                "Das Werbemittel wurde erfolgreich aus dem Presales-Ordner entfernt.",
                "success"
              );
            })
            .catch((e) => {
              this.snackbarService.show(e.error, "danger");
            });

          this.appLoader.close();

          this.reload.emit();
        }
      });
  }

  zoom() {
    this.dialog.open(PresalesItemExpandedDialogComponent, {
      width: "95vw",
      height: "95vh",
      panelClass: "no-spacing",
      data: {
        presalesItem: this.presalesItem,
        mediaAsset: this.presalesItem.mediaAssets[this.mediaAssetIndex],
      },
    });
  }

  openComment() {
    this.dialog.open(ApprovalElementCommentDialogComponent, {
      width: "680px",
      panelClass: "no-spacing",
      maxHeight: "95vh",
      disableClose: true,
      data: {
        comment: this.presalesItem.comment
          ? {
              note: this.presalesItem.comment,
              proposal: "",
            }
          : null,
        type: "text",
        readonly: true,
      },
    });
  }
}
