import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "app-auto-media-asset-animation-dialog",
  templateUrl: "./auto-media-asset-animation-dialog.component.html",
  styleUrls: ["./auto-media-asset-animation-dialog.component.scss"],
})
export class AutoMediaAssetAnimationDialogComponent implements OnInit {
  public loadingTexts: string[] = [
    "Künstliche Intelligenz erfasst Unternehmensinformationen...",
    "Künstliche Intelligenz erstellt Texte...",
    "Künstliche Intelligenz erstellt Bilder...",
    "Künstliche Intelligenz generiert Werbemittel...",
  ];
  public loadingTextIndex: number = 0;
  public loadingInterval: any;
  public animationHoldTime: number = 1000; // ms
  public showAnimation: boolean = false;
  public animationIsDone: boolean = false;
  public closeAfterAnimation: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AutoMediaAssetAnimationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit() {
    await this.delay(500);

    const self = this;
    this.loadingInterval = setInterval(function () {
      if (self.loadingTextIndex + 2 > self.loadingTexts.length) {
        clearInterval(self.loadingInterval);
        self.animationIsDone = true;

        if (self.closeAfterAnimation) {
          self.dialogRef.close();
        }
        return;
      }
      self.loadingTextIndex++;
    }, this.animationHoldTime);
  }

  delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  forceCloseAfterAnimation() {
    if (this.animationIsDone) {
      this.dialogRef.close();
      return;
    }

    this.closeAfterAnimation = true;
  }
}
