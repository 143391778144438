import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { MPMediaAssetUploader } from "app/model/media-asset/MPMediaAssetUploader";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";
import { AddCustomerRegionDialogComponent } from "app/shared/components/dialogs/add-customer-region-dialog/add-customer-region-dialog.component";
import { MPCustomerLocation } from "app/model/marketing-portal/MPCustomerLocation";
import { LogEventType } from "app/model/LogEventType";

@Component({
  selector: "assistant-profile-check-form",
  templateUrl: "./assistant-profile-check-form.component.html",
  styleUrls: ["./assistant-profile-check-form.component.scss"],
})
export class AssistantProfileCheckFormComponent implements OnInit {
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Input() customer: MPCustomer;
  public formGroup: FormGroup;
  public errors = {};
  readonly environment = environment;
  public selectedPrimaryColor: string;
  public selectedSecondaryColor: string;
  public mediaAssets: MPMediaAsset[] = [];
  public uploadMessages: object = {
    title: "Kein Unternehmenslogo gefunden",
    body: "Es konnte kein Unternehmenslogo gefunden",
  };
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private crudService: CrudService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.initCustomerLogo();
  }

  buildForm() {
    this.formGroup = this.fb.group({
      imageId: this.customer ? this.customer.customerMediaAssetId : null,
      customerCorporateColor: this.customer
        ? this.customer.customerCorporateColor
        : null,
      secondaryColor: this.customer ? this.customer.secondaryColor : null,
      locations: this.customer ? this.customer.customerLocations : [],
      mainText1: [this.customer ? this.customer.mainText1 : ""],
      mainText2: [this.customer ? this.customer.mainText2 : ""],
      mainText3: [this.customer ? this.customer.mainText3 : ""],
    });

    this.selectedPrimaryColor = this.convertColorFromDB(
      this.customer.customerCorporateColor
    );

    this.selectedSecondaryColor = this.convertColorFromDB(
      this.customer.secondaryColor
    );
  }

  onChangePrimaryColor($event) {
    this.selectedPrimaryColor = $event;
    this.formGroup.get("customerCorporateColor").setValue($event);

    this.crudService
      .logEvent(
        LogEventType.ASSISTANT,
        "Primäre Farbe wurde geändert",
        $event,
        true
      )
      .subscribe(
        (res) => {},
        (error) => {}
      );
  }

  onChangeSecondaryColor($event) {
    this.selectedSecondaryColor = $event;
    this.formGroup.get("secondaryColor").setValue($event);

    this.crudService
      .logEvent(
        LogEventType.ASSISTANT,
        "Sekundäre Farbe wurde geändert",
        $event,
        true
      )
      .subscribe(
        (res) => {},
        (error) => {}
      );
  }

  convertColorFromDB(color: any): any {
    if (color) {
      if (!color.includes("#") && color.includes("(")) {
        const colorSplit = color.split("(")[1];
        const r = parseInt(colorSplit.split(",")[0]);
        const g = parseInt(colorSplit.split(",")[1]);
        const b = parseInt(colorSplit.split(",")[2]);
        return this.rgbToHex(r, g, b);
      }
    }
    return color;
  }

  rgbToHex(r, g, b) {
    return (
      "#" +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    );
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  initCustomerLogo() {
    if (this.customer.customerMediaAssetId) {
      const newMediaAsset: MPMediaAsset = new MPMediaAsset(
        this.customer.customerMediaAssetId,
        "Logo",
        null,
        null,
        null,
        "image/png",
        null,
        true,
        false,
        true,
        null,
        null,
        null,
        null,
        false,
        null
      );

      newMediaAsset.mediaAssetThumbnailUrl = newMediaAsset.getMediaUrlById();
      newMediaAsset.mediaAssetUrl = newMediaAsset.getMediaUrlById();
      this.mediaAssets.push(newMediaAsset);
    }
  }

  async onChangeMediaAsset($event) {
    this.mediaAssets = $event;

    this.appLoader.open();

    const mpMediaAssetUploader: MPMediaAssetUploader = new MPMediaAssetUploader(
      this.mediaAssets,
      this.crudService,
      null
    );

    await mpMediaAssetUploader
      .upload()
      .then((mediaAssets: MPMediaAsset[]) => {
        this.mediaAssets = mediaAssets;
        this.formGroup
          .get("imageId")
          .setValue(this.mediaAssets[0].mediaAssetId);

        this.crudService
          .logEvent(
            LogEventType.ASSISTANT,
            "Logo wurde geändert",
            this.mediaAssets[0].mediaAssetId,
            true
          )
          .subscribe(
            (res) => {},
            (error) => {}
          );
      })
      .catch((e) => {
        this.appLoader.close();
        this.snackbarService.show(e.error, "danger");
      });

    this.appLoader.close();
  }

  deleteImage() {
    this.mediaAssets = [];
    this.formGroup.get("imageId").setValue(null);
  }

  updateCustomerCD($event) {}

  customerCDUpdated($event) {
    this.crudService
      .logEvent(
        LogEventType.ASSISTANT,
        "Werbetext " + $event + " wurde geändert",
        $event,
        true
      )
      .subscribe(
        (res) => {},
        (error) => {}
      );
  }

  removeLocation($event) {
    const index = $event;

    const location: MPCustomerLocation = this.customer.customerLocations[index];
    this.customer.customerLocations.splice(index, 1);

    this.formGroup.get("locations").setValue(this.customer.customerLocations);

    this.crudService
      .logEvent(LogEventType.ASSISTANT, "Standort wurde entfernt", null, true)
      .subscribe(
        (res) => {},
        (error) => {}
      );
  }

  addLocation() {
    const dialogRef = this.dialog.open(AddCustomerRegionDialogComponent, {
      width: "800px",
      panelClass: "no-spacing",
      height: "auto",
      maxHeight: "100vh",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const locationAddress = res.selectedRegion;

        const location: MPCustomerLocation = new MPCustomerLocation(
          null,
          "Standort " + locationAddress.city,
          "",
          locationAddress,
          null,
          null,
          20
        );

        this.customer.customerLocations.push(location);

        this.formGroup
          .get("locations")
          .setValue(this.customer.customerLocations);

        this.crudService
          .logEvent(
            LogEventType.ASSISTANT,
            "Standort wurde hinzugefügt",
            null,
            true
          )
          .subscribe(
            (res) => {},
            (error) => {}
          );
      }
    });
  }

  onSubmit() {
    const value = this.formGroup.getRawValue();

    this.submitted.emit(value);
  }
}
