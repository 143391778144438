import { Component, Input, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from "@angular/forms";

import { TemplateBasedImage } from "app/model/marketing-portal/TemplateBasedImage";
import { MPObjective } from "app/model/marketing-portal/MPObjective";
import { MPTemplateResolution } from "app/model/marketing-portal/MPTemplateresolution";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CrudService } from "app/views/mapAds/crud.service";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { TVAdsCampaign } from "app/model/marketing-portal/TVAdsCampaign";
import { MPCampaignSettings } from "app/model/marketing-portal/MPCampaignSettings";
import { ATVMediaFormat } from "app/model/marketing-portal/ATVMediaFormat";
import { MPPackage } from "app/model/marketing-portal/MPPackage";
import { MPTopic } from "app/model/marketing-portal/MPTopic";
import { MPGender } from "app/model/marketing-portal/MPGender";
import { MPAgeGroup } from "app/model/marketing-portal/MPAgeGroup";
import { ValidationService } from "app/shared/services/validation.service";
import { datePeriodConfig } from "../config/datePeriodConfig";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { MPMediaUploadFormatHandler } from "app/model/marketing-portal/MPMediaUploadFormatHandler";
import { MPMediaUploadFormatConfiguration } from "app/model/marketing-portal/MPMediaUploadFormatConfiguration";

@Component({
  selector: "create-tv-form",
  templateUrl: "./create-tv-form.component.html",
  styleUrls: ["./create-tv-form.component.scss"],
})
export class CreateTvFormComponent {
  @ViewChild("form") form: NgForm;
  @Input() data?: TVAdsCampaign;
  @Input() duplicate: number;
  @Input() readonly?: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() campaignChannelId?: number = null;
  @Input() customer?: any;
  @Input() selectedTemplateImages?: TemplateBasedImage[] = null;
  @Input() selectedObjective?: MPObjective;
  @Input() selectedResolutions?: MPTemplateResolution[];
  public isLoading: boolean = false;
  public settings: MPCampaignSettings;
  public campaignForm: FormGroup;
  public errors: any = {};
  public mediaFormats: ATVMediaFormat[];
  public packages: MPPackage[];
  public topics: MPTopic[];
  public genders: MPGender[];
  public ageGroups: MPAgeGroup[];
  public mediaAssets: MPMediaAsset[] = [];
  public sendApprovalRequest: boolean = false;
  public mpChannel = MPChannel;
  public videoUploadConfiguration: MPMediaUploadFormatConfiguration;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private crudService: CrudService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.buildForm(this.data);
    this.initData();

    this.videoUploadConfiguration =
      new MPMediaUploadFormatHandler().getATVVideoConfig();
  }

  async initData() {
    setTimeout(() => this.appLoader.open());
    this.isLoading = true;

    try {
      this.settings = await this.crudService.getATVSettings().toPromise();
      this.mediaFormats = await this.crudService
        .getATVMediaFormats()
        .toPromise();
      this.packages = await this.crudService.getATVPackages().toPromise();
      this.topics = await this.crudService.getATVTopics().toPromise();
      this.genders = await this.crudService.getATVGender().toPromise();
      this.ageGroups = await this.crudService.getATVAgeGroups().toPromise();
    } catch (e) {
      this.snackbarService.show(e.error, "danger");
      this.appLoader.close();
    }

    this.isLoading = false;
    setTimeout(() => this.appLoader.close());
  }

  buildForm(items: TVAdsCampaign) {
    this.campaignForm = this.formBuilder.group({
      mpCampaignId: items ? items.mpCampaignId : null,
      campaignName: ["Meine Kampagne", Validators.required],
      campaignPackageId: [
        items && items.packageId ? items.packageId : 0,
        Validators.required,
      ],
      campaignGoalId: [0, Validators.required],
      manualBudget: 0,
      campaignStart: [
        null,
        Validators.compose([
          ValidationService.dateGreaterThan({
            source: "campaignEnd",
            reference: "campaignStart",
            minDays: datePeriodConfig.MIN_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS,
            maxDays: datePeriodConfig.MAX_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS,
          }),
          ValidationService.daysInFuture(
            datePeriodConfig.START_DATE_DAYS_IN_FUTURE
          ),
        ]),
      ],
      campaignEnd: [
        null,
        [
          ValidationService.dateGreaterThan({
            source: "campaignEnd",
            reference: "campaignStart",
            minDays: datePeriodConfig.MIN_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS,
            maxDays: datePeriodConfig.MAX_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS,
          }),
        ],
      ],
      campaignAgeGroupId: [
        items && items.ageGroup ? items.ageGroup.ageGroupId : null,
      ],
      campaignGenderId: [items && items.gender ? items.gender.genderId : null],
      campaignTopicId: [items && items.topic ? items.topic.topicId : null],
      approvalCustomerEmail: [
        this.customer && this.customer.customerMail
          ? this.customer.customerMail
          : "",
      ],
      campaignLocations: [items ? items.locations : [], Validators.required],
      campaignImageIds: [[], Validators.required],
      approvalMessage: "",
    });
    if (this.readonly) {
      this.campaignForm.disable();
    }
  }

  onChangeSendApprovalRequest($event) {
    this.sendApprovalRequest = $event;
  }

  onChangeMediaAssets($event) {
    this.mediaAssets = $event;
  }

  validateErrors() {
    this.errors = this.getValidationErrors();
  }

  getValidationErrors(): Object {
    const errors = {};

    if (this.campaignForm.hasError("startDateLessThanEndDate")) {
      this.campaignForm.controls["campaignEnd"].setErrors({
        startDateLessThanEndDate: true,
      });
    }

    // FormGroup validation errors
    Object.keys(this.campaignForm.controls).forEach((key) => {
      const control = this.campaignForm.get(key);
      const controlErrors: ValidationErrors = control.errors;

      if (controlErrors != null) {
        let error = Object.keys(control.errors)[0];

        // Control has multiple errors - We want to show only one
        if (Object.keys(control.errors).length > 2) {
          // Remove first error
          const firstKey = Object.keys(control.errors)[0];
          delete control.errors[firstKey];

          // Select error
          for (const key of Object.keys(control.errors)) {
            if (key != "validatorValue") {
              error = key;
            }
          }
        }

        errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }
    });

    return errors;
  }

  generatePaymentURL(
    campaignChannelId: number,
    mpCampaignId: number,
    checkoutNeeded: boolean
  ) {
    return `/mapAds/marketing-portal/checkout/campaign/${campaignChannelId}/${mpCampaignId}?checkoutNeeded=${checkoutNeeded}`;
  }

  async onSubmit(isDraft: boolean = false) {
    this.errors = this.getValidationErrors();

    if (this.campaignForm.invalid || Object.keys(this.errors).length) {
      this.snackbarService.show(
        "Bitte fülle alle erforderlichen Felder aus",
        "danger"
      );

      setTimeout(() => {
        let errorMessages = document.querySelector(".error-message");
        if (errorMessages) {
          errorMessages.scrollIntoView();
        }
      });

      return;
    }

    let value = this.campaignForm.getRawValue();

    const {
      mpCampaignId,
      campaignName,
      campaignPackageId,
      campaignGoalId,
      manualBudget,
      campaignStart,
      campaignEnd,
      campaignAgeGroupId,
      campaignGenderId,
      campaignTopicId,
      approvalCustomerEmail,
      campaignLocations,
      campaignImageIds,
      approvalMessage,
    } = value;

    const startDate = new Date(campaignStart);
    let offset = (startDate.getTimezoneOffset() * -1) / 60;

    const endDate = new Date(campaignEnd);
    let offset_enddate = (endDate.getTimezoneOffset() * -1) / 60;

    const data: any = {
      mpCampaignId,
      campaignName: campaignName,
      campaignStart: startDate,
      campaignEnd: endDate,
      channelId: MPChannel.CHANNEL_ID_ADDRESSABLE_TV,
      timezone: offset,
      timezone_enddate: offset_enddate,
      campaignPackageId,
      manualBudget,
      campaignGoalId,
      campaignAgeGroupId: campaignAgeGroupId,
      campaignGenderId: campaignGenderId,
      campaignTopicId: campaignTopicId,
      campaignLocations: campaignLocations,
      campaignImageIds: campaignImageIds,
      customerId: this.customer ? this.customer.customerId : null,
    };

    const request: Promise<any> = this.isEdit
      ? this.crudService
          .editATVCampaign(this.data.atvCampaignId, data)
          .toPromise()
      : this.crudService.createATVCampaign(data).toPromise();

    this.appLoader.open();

    await request
      .then(async (res) => {
        if (this.isEdit) {
          this.snackbarService.show(
            "Deine Marketing-Kampagne wurde erfolgreich bearbeitet"
          );
          this.router.navigateByUrl("/mapAds/marketing-portal/campaigns");
          setTimeout(() => this.appLoader.close());
        } else {
          this.snackbarService.show(
            "Deine Marketing-Kampagne wurde erfolgreich erstellt"
          );

          const atvCampaignId = Number(res);

          let atvCampaign = null;

          await this.crudService
            .getATVCampaign(atvCampaignId)
            .toPromise()
            .then((res) => {
              atvCampaign = res;
            })
            .catch((e) => {
              this.snackbarService.show(e.error, "danger");
            });

          if (this.sendApprovalRequest) {
            const body = {
              userMessage: approvalMessage,
              optionalMail: approvalCustomerEmail,
            };

            await this.crudService
              .requestCustomerApproval(atvCampaign.mpCampaignId, body)
              .toPromise()
              .then((res) => {
                this.snackbarService.show(
                  "Deine Freigabeanfrage wurde erfolgreich gesendet",
                  "success"
                );
              })
              .catch((e) => {
                this.snackbarService.show(e.error, "danger");
              });
          }

          let redirectUrl = isDraft
            ? "/mapAds/marketing-portal/campaigns"
            : this.generatePaymentURL(
                this.campaignChannelId,
                atvCampaign.mpCampaignId,
                this.settings.CheckoutNeeded
              );

          this.appLoader.close();

          this.router.navigateByUrl(redirectUrl);
        }
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
        this.appLoader.close();
      });
  }
}
