import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { MPVideoStatus } from "app/model/marketing-portal/MPVideoStatus";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "media-asset-view-card",
  templateUrl: "./media-asset-view-card.component.html",
  styleUrls: ["./media-asset-view-card.component.scss"],
})
export class MediaAssetViewCardComponent implements OnInit {
  @Input() mediaAsset: MPMediaAsset;
  @Input() googleDisplayMediaFormats: any[] = null;
  public showSkeletonAnimation: boolean = true;
  public skeletonAnimationTheme: object;
  public readinessInterval = null;
  public size: number = 160;
  public mediaFormatLabel: string;
  constructor(
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.setSkeletonTheme();
    this.updateReadiness();
    this.setMediaFormatLabel();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty("googleDisplayMediaFormats") &&
      changes.googleDisplayMediaFormats.currentValue.length
    ) {
      this.setMediaFormatLabel();
    }
  }

  setMediaFormatLabel() {
    let label = null;

    if (
      this.googleDisplayMediaFormats &&
      this.googleDisplayMediaFormats.length
    ) {
      label = this.googleDisplayMediaFormats.find(
        (googleDisplayMediaFormat) =>
          googleDisplayMediaFormat.googleDisplayMediaFormatId ===
          this.mediaAsset.googleDisplayMediaFormatId
      ).googleDisplayMediaFormatValue;
    }

    if (this.mediaAsset.mediaFormatName) {
      label = this.mediaAsset.mediaFormatName;
    }

    this.mediaFormatLabel = label;
  }

  loadImage() {
    this.showSkeletonAnimation = false;
  }

  loadedVideoMetaData() {
    this.showSkeletonAnimation = false;
  }

  setSkeletonTheme() {
    this.skeletonAnimationTheme = {
      width: this.size + "px",
      height: this.size + "px",
      "margin-bottom": 0,
    };
  }

  updateReadiness() {
    if (this.mediaAsset.hasOwnProperty("isReady") && !this.mediaAsset.isReady) {
      const self = this;
      const maxRecurrences = 15;
      let counter = 0;
      this.readinessInterval = setInterval(function () {
        counter++;
        self.crudService
          .getVideoStatusByMediaAssetId(self.mediaAsset.mediaAssetId)
          .subscribe(async (res: MPVideoStatus) => {
            if (!res || res.status == "failed") {
              clearInterval(self.readinessInterval);
              // self.snackbarService.show(
              //   "Bei der Generierung des Videos ist ein unerwarteter Fehler aufgetreten",
              //   "danger"
              // );
              return;
            }

            if (res.isReady) {
              await self.delay(1500);
              self.mediaAsset.isReady = res.isReady;
              clearInterval(self.readinessInterval);
            }

            if (counter > maxRecurrences) {
              clearInterval(self.readinessInterval);
              // self.snackbarService.show(
              //   "Bei der Generierung des Videos ist ein unerwarteter Fehler aufgetreten",
              //   "danger"
              // );
            }
          });
      }, 3000);
    }
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
