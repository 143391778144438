import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EditorMediaAssetResolution } from "app/model/marketing-portal/EditorMediaAssetResolution";
import { MPFontFamily } from "app/model/marketing-portal/MPFontFamily";
import { MPFontWeight } from "app/model/marketing-portal/MPFontWeight";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { LayoutService } from "app/shared/services/layout.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";

@Component({
  selector: "marketing-portal-editor-template-configurator",
  templateUrl: "./marketing-portal-editor-template-configurator.component.html",
  styleUrls: ["./marketing-portal-editor-template-configurator.component.scss"],
})
export class MarketingPortalEditorTemplateConfiguratorComponent
  implements OnInit
{
  @Output() onApply: EventEmitter<any> = new EventEmitter();
  @Input() template;
  @Input() templates;
  @Input() customerId;
  @Input() customerLogo;
  @Input() channelId;
  @Input() isOpenMenu: boolean;
  @Input() formatMediaAssets: any;
  @Input() googleDisplayMediaFormatId?: number;
  @Input() isEdit: boolean;
  @Input() mediaAssetId: number;

  public previewImageUrl: string;
  public updatedTemplateConfig: any;
  public templateMediaAssets: EditorMediaAssetResolution[] = [];
  public previewResolutionId: number;
  public previewImageId: number;
  public inputChanged: boolean = true;
  public fontFamilies: MPFontFamily[];
  public fontWeights: MPFontWeight[];

  readonly environment = environment;

  constructor(
    private appLoader: AppLoaderService,
    private crudService: CrudService,
    private snackbarService: SnackbarService,
    public layout: LayoutService
  ) {}

  async ngOnInit() {
    this.setSetTemplateProperties();
    this.setInitMediaAssets();

    await this.getFontsForTemplate();
  }

  async getFontsForTemplate() {
    setTimeout(() => this.appLoader.open());

    var fontFamilyPromise: any = null;
    var fontWeightPromise: any = null;

    fontFamilyPromise = this.crudService
      .getFontFamilyForTemplateSet(this.template.templateSetId)
      .toPromise();
    fontWeightPromise = this.crudService
      .getFontWeightForTemplateSet(this.template.templateSetId)
      .toPromise();

    //not set, except restoring
    if (!this.template.templateConfigModel.FontFamily)
      this.template.templateConfigModel.FontFamily = this.template.FontFamily;
    if (!this.template.templateConfigModel.FontWeight)
      this.template.templateConfigModel.FontWeight = this.template.FontWeight;

    await fontFamilyPromise
      .then((res) => {
        this.fontFamilies = res;
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    await fontWeightPromise
      .then((res) => {
        this.fontWeights = res;
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });
    setTimeout(() => this.appLoader.close());
  }

  setPreviewMediaIds(init = false) {
    const templateMediaAsset = this.templateMediaAssets[0];

    if (init) {
      this.previewResolutionId = templateMediaAsset.resolutionId;
    }

    this.previewImageId = templateMediaAsset.imageId;
  }

  getTemplateMediaAssetByResolutionId(resolutionId: number) {
    return this.templateMediaAssets.find(
      (asset) => asset.resolutionId === resolutionId
    );
  }

  onChangePreviewFormat(resolutionId: number) {
    const templateMediaAsset =
      this.getTemplateMediaAssetByResolutionId(resolutionId);
    this.previewResolutionId = templateMediaAsset.resolutionId;
    this.previewImageId = templateMediaAsset.imageId;
  }

  setSetTemplateProperties() {
    // const setTemplate = this.getDefaultSetTemplate(this.template.templateSetId);
    // this.template.singleTemplates = setTemplate.singleTemplates;
    // this.template.templateSetId = setTemplate.templateSetId;
    // if (this.formatMediaAssets) {
    //   this.setSetTemplateInitImages();
    // }
  }

  setSetTemplateInitImages() {
    this.template.singleTemplates.forEach((singleTemplate, index) => {
      if (this.formatMediaAssets[singleTemplate.formatId].mediaAssets.length) {
        this.template.singleTemplates[index].imageId =
          this.formatMediaAssets[
            singleTemplate.formatId
          ].mediaAssets[0].mediaAssetId;
      }
    });
  }

  isDefaultSetTemplate(imageId: number): boolean {
    let found: boolean = false;

    this.templates.forEach((template) => {
      template.singleTemplates.forEach((singleTemplate) => {
        if (singleTemplate.imageId === imageId) {
          found = true;
        }
      });
    });

    return found;
  }

  getDefaultSetTemplate(templateSetId: number) {
    let setTemplate = null;

    this.templates.forEach((template) => {
      if (template.templateSetId === templateSetId) {
        setTemplate = template;
      }
    });

    return setTemplate;
  }

  setInitMediaAssets() {
    const singleTemplates = this.template.singleTemplates;
    const data: any = [];
    for (let i = 0; i < singleTemplates.length; i++) {
      const { imageId, resolution, resolutionId, resolutionDisplayName } =
        singleTemplates[i];

      let displayName = resolution;

      if (resolutionDisplayName) {
        displayName = ` (${resolutionDisplayName})`;
      }

      data.push({
        imageId,
        displayName,
        resolutionId,
      });
    }

    this.templateMediaAssets = data;

    this.setPreviewMediaIds(true);
  }

  updateTemplateMediaAssets(response) {
    response.forEach((item, index) => {
      this.templateMediaAssets[index].imageId = item.imageId;
      this.templateMediaAssets[index].formatId = item.formatId;
    });
  }

  setUpdatedTemplateConfig(templateConfig) {
    this.updatedTemplateConfig = templateConfig;
  }

  setInputChanged(inputChanged) {
    this.inputChanged = inputChanged;
  }

  async generateImage(apply = false, mediaAssetId: number = null) {
    this.appLoader.open(
      "Dein individuelles Design wird erstellt...",
      "design_services"
    );

    this.setInputChanged(false);

    const originalMediaAssetId = this.mediaAssetId ? this.mediaAssetId : null;
    const isDraft = this.isEdit && !apply;

    const data = {
      templateSetId: this.template.templateSetId,
      template: this.template.templateExternalId,
      hasGlobalColor: false,
      globalColorCode: this.template.templateConfigModel.globalColorCode,
      modifications: this.updatedTemplateConfig,
      singleTemplates: this.template.singleTemplates,
      FontFamily: this.template.templateConfigModel.FontFamily,
      FontWeight: this.template.templateConfigModel.FontWeight,
      isNewBanner: this.template.templateConfigModel.isNewBanner,
    };

    await this.crudService
      .createTemplateBasedImageSet(
        this.channelId,
        data,
        this.customerId,
        originalMediaAssetId,
        isDraft
      )
      .toPromise()
      .then((res: any) => {
        if (!res) {
          this.snackbarService.show(
            "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut oder nimm Kontakt mit uns auf!",
            "danger"
          );
          return;
        }

        this.updateTemplateMediaAssets(res);
        this.setPreviewMediaIds(true);

        if (apply) {
          this.onApply.emit({
            templateMediaAssets: this.templateMediaAssets,
            data,
          });
        }
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.appLoader.close();
  }

  isInitialPreviewImage() {
    // TODO:
    return false;
    // return this.imageId == this.template.templateImageId;
  }

  onApplyTemplateImage() {
    if (this.inputChanged || this.isInitialPreviewImage() || this.isEdit) {
      this.generateImage(true);
    } else {
      const data = {
        template: this.template.templateExternalId,
        hasGlobalColor: false,
        globalColorCode: this.template.templateConfigModel.globalColorCode,
        modifications: this.template.templateConfigModel.modifications,
      };

      data["templateSetId"] = this.template.templateSetId;

      this.onApply.emit({
        templateMediaAssets: this.templateMediaAssets,
        data,
      });
    }
  }
}
