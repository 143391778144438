import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { MPLibaryMediaAsset } from "app/model/media-asset/MPLibaryMediaAsset";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { MPMediaImportType } from "app/model/marketing-portal/MPMediaImportType";
import { MPMediaUploadFormatConfiguration } from "app/model/marketing-portal/MPMediaUploadFormatConfiguration";
import { MPMediaUploadFormatHandler } from "app/model/marketing-portal/MPMediaUploadFormatHandler";
import { MarketingPortalEditorDialogComponent } from "app/shared/components/dialogs/marketing-portal/marketing-portal-editor-dialog/marketing-portal-editor-dialog.component";
import { MarketingPortalVideoEditorDialogComponent } from "app/shared/components/dialogs/marketing-portal/marketing-portal-video-editor-dialog/marketing-portal-video-editor-dialog.component";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";

@Component({
  selector: "media-assets-uploader",
  templateUrl: "./media-assets-uploader.component.html",
  styleUrls: ["./media-assets-uploader.component.scss"],
})
export class MediaAssetsUploaderComponent implements OnInit {
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() getFile?: EventEmitter<any> = new EventEmitter();
  @Input() title?: string = "Werbemittel hinzufügen";
  @Input() channelId: number;
  @Input() customerId?: number = null;
  @Input() customerLogo?: string = null;
  @Input() googleDisplayMediaFormatId?: number;
  @Input() mediaAssets?: MPMediaAsset[] = [];
  @Input() maxImages?: number = 1;
  @Input() maxVideos?: number = 1;
  @Input() disableVideoUpload?: boolean = false;
  @Input() disableImageUpload?: boolean = false;
  @Input() disableEditor?: boolean = false;
  @Input() disableVideoEditor?: boolean = false;
  @Input() readonly?: boolean = false;
  @Input() width?: number = 600;
  @Input() height?: number = 600;
  @Input() minHeight?: number = 300;
  @Input() minWidth?: number = 300;
  @Input() videoUploadConfiguration?: MPMediaUploadFormatConfiguration;
  @Input() imageUploadConfiguration?: MPMediaUploadFormatConfiguration;
  @Input() hideMediaAssetOptions?: any[] = null;
  @Input() showMediaAssetOptions?: boolean = false;
  @Input() mediaAssetFormatId?: number = null;
  @Input() formatMediaAssets?: any = null;
  @Input() hideAdMaterials?: boolean = false;
  @Input() resolutionId?: number = null;
  @Input() error?: boolean = false;
  @Input() forceAspectRatio?: boolean = false;
  @Input() messages?: object = {
    title: "Kein Werbemittel hinzugefügt",
    body: "Für diese Kampagne hast Du keine Werbemittel hinzugefügt",
  };

  public maxFiles: number;
  public mpMediaImportType: number;
  public MEDIA_IMPORT_TYPE_VIDEO: number =
    MPMediaImportType.MEDIA_IMPORT_TYPE_VIDEO;
  public MEDIA_IMPORT_TYPE_IMAGE: number =
    MPMediaImportType.MEDIA_IMPORT_TYPE_IMAGE;
  public videoMediaAssets: any = [];
  public imageMediaAssets: any = [];

  constructor(
    private dialog: MatDialog,
    private crudService: CrudService,
    private appLoader: AppLoaderService
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  init() {
    this.setInitValues();
    this.setInitMediaImportType();
    this.setUploadConfiguration();
  }

  setUploadConfiguration() {
    if (!this.imageUploadConfiguration) {
      this.imageUploadConfiguration =
        new MPMediaUploadFormatHandler().getDefaultImageConfig(
          this.minHeight,
          this.minWidth
        );
    }
  }

  setInitValues() {
    if (this.mediaAssets.length) {
      if (this.mediaAssets[0].isImage) {
        this.imageMediaAssets = this.mediaAssets;
      } else {
        this.videoMediaAssets = this.mediaAssets;
      }
    }
  }

  updateValues() {
    if (this.isImage()) {
      this.imageMediaAssets = this.mediaAssets;
    } else {
      this.videoMediaAssets = this.mediaAssets;
    }
  }

  setInitMediaImportType() {
    let isFirstImage = true;

    if (this.mediaAssets.length) {
      isFirstImage = this.mediaAssets[0].isImage;
    }

    if (!this.disableImageUpload && isFirstImage) {
      this.setMpMediaImportType(this.MEDIA_IMPORT_TYPE_IMAGE);
      this.setImageMaxFiles();
    } else {
      this.setMpMediaImportType(this.MEDIA_IMPORT_TYPE_VIDEO);
      this.showMediaAssetOptions = false;
      this.setVideoMaxFiles();
    }
  }

  setImageMaxFiles() {
    this.maxFiles = this.maxImages;
  }

  setVideoMaxFiles() {
    this.maxFiles = this.maxVideos;
  }

  setToVideo() {
    if (this.readonly) {
      return;
    }

    this.updateValues();
    this.mediaAssets = this.videoMediaAssets;
    this.onChange.emit(this.mediaAssets);
    this.setMpMediaImportType(this.MEDIA_IMPORT_TYPE_VIDEO);
    this.setVideoMaxFiles();
    this.showMediaAssetOptions = false;
  }

  setToImage() {
    if (this.readonly) {
      return;
    }

    this.updateValues();
    this.mediaAssets = this.imageMediaAssets;
    this.onChange.emit(this.mediaAssets);
    this.setMpMediaImportType(this.MEDIA_IMPORT_TYPE_IMAGE);
    this.setImageMaxFiles();
    this.showMediaAssetOptions = true;
  }

  isImage() {
    return this.getMpMediaImportType() === this.MEDIA_IMPORT_TYPE_IMAGE;
  }

  isVideo() {
    return this.getMpMediaImportType() === this.MEDIA_IMPORT_TYPE_VIDEO;
  }

  setMpMediaImportType(type: number) {
    this.mpMediaImportType = type;
  }

  getMpMediaImportType() {
    return this.mpMediaImportType;
  }

  onDelete(index) {
    this.mediaAssets.splice(index, 1);
    this.onChange.emit(this.mediaAssets);
  }

  addMediaAsset(mpMediaAsset: MPMediaAsset) {
    this.mediaAssets.push(mpMediaAsset);
    this.onChange.emit(this.mediaAssets);
  }

  onEdit(index) {
    let mediaAsset: MPMediaAsset = this.mediaAssets[index];

    if (mediaAsset.isImage) {
      this.openEditor(mediaAsset);
      return;
    }

    this.openVideoEditor(mediaAsset);
  }

  addLibaryTemplateFile(obj: Object) {
    const mpLibaryMediaAsset: MPLibaryMediaAsset = obj["mpLibaryMediaAsset"];

    const newMediaAsset: MPMediaAsset = new MPMediaAsset(
      mpLibaryMediaAsset.ImageId,
      mpLibaryMediaAsset.ImageName,
      mpLibaryMediaAsset.ImageWidth,
      mpLibaryMediaAsset.ImageHeight,
      null,
      null,
      false,
      true,
      false,
      true,
      null,
      null,
      null,
      null,
      mpLibaryMediaAsset.IsPartOfSet,
      mpLibaryMediaAsset.TemplateId,
      this.mediaAssetFormatId
    );

    newMediaAsset.setURL();
    newMediaAsset.setThumbnailURL();

    this.addMediaAsset(newMediaAsset);
  }

  removeTemplateById(mediaAssetId) {
    let index = this.mediaAssets.findIndex(
      (mediaAsset: MPMediaAsset) => mediaAsset.mediaAssetId === mediaAssetId
    );

    if (index != -1) {
      this.onDelete(index);
    }
  }

  onFileDroppedEvent($event) {
    this.fileSelect($event);
  }

  addVideoMediaAsset($event) {
    this.addMediaAsset($event.newMediaAsset);
  }

  fileSelect(event) {
    const files: File[] = event;

    for (let i = 0; i < files.length; i++) {
      const newFile = files[i];

      if (this.getFile) {
        this.getFile.emit(newFile);
      }
      const reader = new FileReader();

      reader.onload = (_event) => {
        const name = newFile.name;
        const format = newFile.type;
        const size = newFile.size;
        const data = reader.result;
        const isVideo = this.isVideo();
        const isImage = this.isImage();

        const newMediaAsset = new MPMediaAsset(
          null,
          name,
          null,
          null,
          size,
          format,
          false,
          isImage,
          isVideo,
          true,
          null,
          data,
          null,
          null,
          false,
          null,
          this.mediaAssetFormatId
        );

        this.addMediaAsset(newMediaAsset);
      };
      reader.readAsDataURL(newFile);
    }
  }

  async openEditor(mediaAsset: MPMediaAsset = null) {
    const dialogRef = this.dialog.open(MarketingPortalEditorDialogComponent, {
      width: "100vw",
      height: "auto",
      maxWidth: "100vw",
      maxHeight: "auto",
      panelClass: "full-screen-dialog",
      data: {
        customerId: this.customerId,
        customerLogo: this.customerLogo,
        channelId: this.channelId,
        mediaAsset: mediaAsset,
        formatMediaAssets: this.formatMediaAssets,
        resolutionId: this.resolutionId,
        googleDisplayMediaFormatId: this.googleDisplayMediaFormatId,
      },
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        const { templateMediaAssets, data } = res.templateMediaAssets;

        const allMediaAssts = [];

        templateMediaAssets.forEach((templateMediaAsset) => {
          const id = templateMediaAsset.imageId;
          const name = templateMediaAsset.displayName;
          const formatId = templateMediaAsset.formatId
            ? templateMediaAsset.formatId
            : null;
          const isPartOfSet = data.hasOwnProperty("templateSetId");
          const templateId = data.hasOwnProperty("templateSetId")
            ? data.templateSetId
            : data.templateId;
          const mediaAssetUrl =
            environment.imageServerUrl + "api/Public/GetMediaAsset?id=" + id;
          const mediaAssetThumbnailUrl =
            mediaAssetUrl + "&width=300&height=300";

          const newMediaAsset = new MPMediaAsset(
            id,
            name,
            null,
            null,
            null,
            "image/png",
            false,
            true,
            false,
            true,
            null,
            null,
            mediaAssetUrl,
            mediaAssetThumbnailUrl,
            isPartOfSet,
            templateId,
            formatId
          );

          // Check if is edit mode
          if (mediaAsset) {
            this.removeTemplateById(mediaAsset.mediaAssetId);
          }

          if (templateMediaAsset.formatId === this.mediaAssetFormatId) {
            this.mediaAssets.push(newMediaAsset);
          }

          allMediaAssts.push(newMediaAsset);
        });

        this.onChange.emit(allMediaAssts);
      }
    });
  }

  async openVideoEditor(mediaAsset: MPMediaAsset = null) {
    const dialogRef = this.dialog.open(
      MarketingPortalVideoEditorDialogComponent,
      {
        width: "100vw",
        height: "auto",
        maxWidth: "100vw",
        maxHeight: "auto",
        panelClass: "full-screen-dialog",
        data: {
          customerId: this.customerId,
          customerLogo: this.customerLogo,
          channelId: this.channelId,
          mediaAsset: mediaAsset,
          resolutionId: this.resolutionId,
        },
      }
    );

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const { mediaAssetId, templateId, templateName } =
          res.templateMediaAsset;

        const newMediaAsset = new MPMediaAsset(
          mediaAssetId,
          templateName,
          null,
          null,
          null,
          "image/mp4",
          false,
          false,
          true,
          false,
          null,
          null,
          null,
          null,
          false,
          templateId
        );

        newMediaAsset.setURL();
        newMediaAsset.setThumbnailURL();

        if (mediaAsset) {
          this.removeTemplateById(mediaAsset.mediaAssetId);
        }

        this.addMediaAsset(newMediaAsset);
      }
    });
  }
}
