import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MPMediaAssetSuggestions } from "app/model/marketing-portal/MPMediaAssetSuggestions";
import { MPMediaAssetTextSuggestion } from "app/model/marketing-portal/MPMediaAssetTextSuggestion";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "app-text-suggestions-dialog",
  templateUrl: "./text-suggestions-dialog.component.html",
  styleUrls: ["./text-suggestions-dialog.component.scss"],
})
export class TextSuggestionsDialogComponent implements OnInit {
  public isLoading: boolean;
  public selection: any;
  public mediaAssetSuggestions: MPMediaAssetSuggestions;

  constructor(
    private crudService: CrudService,
    private snackbarService: SnackbarService,
    private appLoader: AppLoaderService,
    public dialogRef: MatDialogRef<TextSuggestionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit() {
    this.isLoading = true;

    const { customerId, templateId } = this.data;
    await this.crudService
      .getMediaAssetSuggestions(customerId, templateId)
      .toPromise()
      .then((res: any) => {
        this.mediaAssetSuggestions = res;
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.isLoading = false;
  }

  onSelect(text: string) {
    this.selection = text;
  }

  onClose() {
    this.dialogRef.close();
  }

  onApply() {
    this.dialogRef.close({ text: this.selection.Text });
  }

  async onDeleteTextSuggestion(textId: number) {
    this.appLoader.open();
    const customerId = this.data.customerId;

    await this.crudService
      .deleteCustomerText(customerId, textId)
      .toPromise()
      .then((res) => {
        this.snackbarService.show(
          "Der Werbetext wurde erfolgreich entfernt",
          "success"
        );
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.removeTextSuggestionFromList(textId);
    this.appLoader.close();
  }

  removeTextSuggestionFromList(textId: number) {
    const index = this.mediaAssetSuggestions.Texts.findIndex(
      (textSuggestion: MPMediaAssetTextSuggestion) =>
        textSuggestion.Id === textId
    );
    this.mediaAssetSuggestions.Texts.splice(index, 1);
  }
}
