import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LogEventType } from "app/model/LogEventType";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { MPCustomerLocation } from "app/model/marketing-portal/MPCustomerLocation";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { MPMediaAssetUploader } from "app/model/media-asset/MPMediaAssetUploader";
import { UploadedFile } from "app/model/UploadedFile";
import { AddCustomerRegionDialogComponent } from "app/shared/components/dialogs/add-customer-region-dialog/add-customer-region-dialog.component";
import { CustomerCrawlerAnimationDialogComponent } from "app/shared/components/dialogs/customer-crawler-animation-dialog/customer-crawler-animation-dialog.component";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { ValidationService } from "app/shared/services/validation.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { CustomFileUploader } from "app/views/mapAds/custom_file_uploader";
import { environment } from "environments/environment";

@Component({
  selector: "create-customer-form",
  templateUrl: "./create-customer-form.component.html",
  styleUrls: ["./create-customer-form.component.scss"],
})
export class CreateCustomerFormComponent implements OnInit {
  @ViewChild("form") form: NgForm;
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Input() readonly: boolean = false;
  @Input() data: any = null;
  public formGroup: FormGroup;
  public errors: any = {};
  readonly environment = environment;
  public selectedPrimaryColor: string;
  public selectedSecondaryColor: string;
  public mediaAssets: MPMediaAsset[] = [];
  public uploadMessages: object = {
    title: "Kein Unternehmenslogo gefunden",
    body: "Es konnte kein Unternehmenslogo gefunden",
  };
  // public showContact: boolean = false;
  // public showLogo: boolean = false;
  // public cats: any = [];
  // public customUploader: CustomFileUploader;
  // public uploadedFiles: Array<UploadedFile>;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private crudService: CrudService,
    private router: Router,
    private appLoader: AppLoaderService,
    private dialog: MatDialog
  ) {
    // this.uploadedFiles = [];
  }

  async ngOnInit() {
    this.buildFormGroup(this.data);
    this.initCustomerLogo(this.data);

    // this.cats = await this.crudService.GetAllCustomerCategories().toPromise();
    // if (!this.data) {
    //   this.formGroup.get("categoryId").setValue(this.cats[0].categoryId);
    // }
  }

  initCustomerLogo(items) {
    if (this.data && this.data.customerMediaAssetId) {
      const newMediaAsset: MPMediaAsset = new MPMediaAsset(
        this.data.customerMediaAssetId,
        "Logo",
        null,
        null,
        null,
        "image/png",
        null,
        true,
        false,
        true,
        null,
        null,
        null,
        null,
        false,
        null
      );

      newMediaAsset.mediaAssetThumbnailUrl = newMediaAsset.getMediaUrlById();
      newMediaAsset.mediaAssetUrl = newMediaAsset.getMediaUrlById();
      this.mediaAssets.push(newMediaAsset);
    }
  }

  async onChangeMediaAsset($event) {
    this.mediaAssets = $event;

    this.appLoader.open();

    const mpMediaAssetUploader: MPMediaAssetUploader = new MPMediaAssetUploader(
      this.mediaAssets,
      this.crudService,
      null
    );

    await mpMediaAssetUploader
      .upload()
      .then((mediaAssets: MPMediaAsset[]) => {
        this.mediaAssets = mediaAssets;
        this.formGroup
          .get("imageId")
          .setValue(this.mediaAssets[0].mediaAssetId);
      })
      .catch((e) => {
        this.appLoader.close();
        this.snackbarService.show(e.error, "danger");
      });

    this.appLoader.close();
  }

  onChangePrimaryColor($event) {
    this.selectedPrimaryColor = $event;
    this.formGroup.get("customerCorporateColor").setValue($event);
  }

  onChangeSecondaryColor($event) {
    this.selectedSecondaryColor = $event;
    this.formGroup.get("secondaryColor").setValue($event);
  }

  removeLocation($event) {
    const index = $event;

    const location: MPCustomerLocation = this.data.customerLocations[index];
    this.data.customerLocations.splice(index, 1);

    this.formGroup.get("locations").setValue(this.data.customerLocations);
  }

  addLocation() {
    const dialogRef = this.dialog.open(AddCustomerRegionDialogComponent, {
      width: "800px",
      panelClass: "no-spacing",
      height: "auto",
      maxHeight: "100vh",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const locationAddress = res.selectedRegion;

        const location: MPCustomerLocation = new MPCustomerLocation(
          null,
          "Standort " + locationAddress.city,
          "",
          locationAddress,
          null,
          null,
          20
        );

        this.data.customerLocations.push(location);

        this.formGroup.get("locations").setValue(this.data.customerLocations);
      }
    });
  }

  async buildFormGroup(customer: MPCustomer) {
    const initProtocol = this.getInitProtocol();
    const initHomepage = this.getInitHomepage();

    this.formGroup = this.fb.group({
      id: [customer ? customer.customerId : null],
      customerExternalId: [customer ? customer.customerExternalId : null],
      name: [customer ? customer.customerName : "", Validators.required],
      homepage: [initHomepage],
      protocol: [initProtocol],
      imageId: customer ? customer.customerMediaAssetId : null,
      customerCorporateColor: customer ? customer.customerCorporateColor : null,
      secondaryColor: customer ? customer.secondaryColor : null,
      email: [
        customer && customer.customerMail ? customer.customerMail : "",
        ValidationService.emailValidator,
      ],

      description: [customer ? customer.customerDescription : ""],
      mainText1: [
        customer && customer.mainText1 ? customer.mainText1.slice(0, 149) : "",
      ],
      mainText2: [
        customer && customer.mainText2 ? customer.mainText2.slice(0, 149) : "",
      ],
      mainText3: [
        customer && customer.mainText3 ? customer.mainText3.slice(0, 149) : "",
      ],
      locations: customer ? customer.customerLocations : [],
      // categoryId: [
      //   items && items.category ? items.category.categoryId : "",
      //   Validators.required,
      // ],
    });

    // Set inital colors
    this.selectedPrimaryColor = this.convertColorFromDB(
      customer.customerCorporateColor
    );

    this.selectedSecondaryColor = this.convertColorFromDB(
      customer.secondaryColor
    );

    // // Set initial validator for homepage
    // this.applyValidatorsForNonEmptyField("homepage", [
    //   ValidationService.urlValidator,
    // ]);

    if (this.readonly) {
      this.formGroup.disable();
    }
  }

  onChangeUrl($event) {
    let value = $event.target.value;

    this.applyValidatorsForNonEmptyField("homepage", [
      ValidationService.urlValidator,
    ]);

    if (!value) {
      return;
    }

    value = value.replace(/^https?:\/\//, "");
    this.formGroup.get("homepage").setValue(value);
  }

  getInitProtocol() {
    let protocol = "https://";

    if (
      this.data &&
      this.data.customerHomepage &&
      this.data.customerHomepage.includes("http://")
    ) {
      protocol = "http://";
    }

    return protocol;
  }

  getInitHomepage() {
    let homepage = "";

    if (this.data && this.data.customerHomepage) {
      homepage = this.data.customerHomepage.replace(/^https?:\/\//, "");
    }

    return homepage;
  }

  convertColorFromDB(color: any): any {
    if (color) {
      if (!color.includes("#") && color.includes("(")) {
        const colorSplit = color.split("(")[1];
        const r = parseInt(colorSplit.split(",")[0]);
        const g = parseInt(colorSplit.split(",")[1]);
        const b = parseInt(colorSplit.split(",")[2]);
        return this.rgbToHex(r, g, b);
      }
    }
    return color;
  }

  rgbToHex(r, g, b) {
    return (
      "#" +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    );
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  submit() {
    this.form.onSubmit(undefined);
  }

  deleteImage() {
    this.mediaAssets = [];
    this.formGroup.get("imageId").setValue(null);
  }

  markFormControls(controls) {
    for (const key of Object.keys(controls)) {
      controls[key].markAsTouched();
      if (controls[key].hasOwnProperty("controls")) {
        this.markFormControls(controls[key].controls);
      }
    }
  }

  getValidationErrors(): void {
    this.errors = {};
    this.getValidationErrorsByControls(this.formGroup.controls);
  }

  getValidationErrorsByControls(controls) {
    Object.keys(controls).forEach((key) => {
      const control = controls[key];
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        const error = Object.keys(control.errors)[0];
        console.log("error", control, error);
        this.errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }

      if (controls[key].hasOwnProperty("controls")) {
        this.getValidationErrorsByControls(controls[key].controls);
      }
    });
  }

  applyValidatorsForNonEmptyField(name, validators) {
    if (this.formGroup.get(name).value === "") {
      this.formGroup.get(name).clearValidators();
    } else {
      this.formGroup.get(name).setValidators(validators);
    }
    this.formGroup.get(name).updateValueAndValidity();
  }

  async onSubmit() {
    this.applyValidatorsForNonEmptyField("homepage", [
      ValidationService.urlValidator,
    ]);

    this.applyValidatorsForNonEmptyField("email", [
      ValidationService.emailValidator,
    ]);

    this.markFormControls(this.formGroup.controls);

    if (this.formGroup.invalid) {
      this.getValidationErrors();
      console.log("errors", this.errors);
      this.snackbarService.show(
        "Bitte fülle alle erforderlichen Felder aus",
        "danger"
      );

      setTimeout(() => {
        let errorMessages = document.querySelector(".error-message");
        if (errorMessages) {
          errorMessages.scrollIntoView();
        }
      });
      return;
    }

    // Process data
    const value = this.formGroup.getRawValue();

    const newCustomer = {
      customerId: value.id,
      customerExternalId: value.customerExternalId,
      customerName: value.name,
      customerDescription: value.description == "" ? null : value.description,
      customerMediaAssetId: value.imageId,
      customerMail: value.email == "" ? null : value.email,
      customerPhone: value.phone == "" ? null : value.phone,
      customerHomepage:
        value.homepage == "" ? null : value.protocol + value.homepage,
      customerIsDeleted: false,
      customerLocations: value.locations,
      customerCorporateColor: value.customerCorporateColor,
      secondaryColor: value.secondaryColor,
      mainText1: value.mainText1,
      mainText2: value.mainText2,
      mainText3: value.mainText3,

      // category: {
      //   categoryId: value.categoryId,
      // },
    };

    this.appLoader.open();

    try {
      await this.crudService.updateCustomer(newCustomer).toPromise();
      await this.crudService.UpdateCustomerCD(newCustomer).toPromise();
      await this.crudService
        .updateCustomerLocations(
          newCustomer.customerId,
          newCustomer.customerLocations ? newCustomer.customerLocations : []
        )
        .toPromise();
      this.snackbarService.show(
        "Der Kunde wurde erfolgreich geändert",
        "success"
      );
    } catch (e) {
      this.snackbarService.show(e.error, "danger");
      this.appLoader.close();
      return;
    }

    this.appLoader.close();

    if (this.isHomepageChanged(newCustomer) && newCustomer.customerHomepage) {
      const result: any = await this.updateCustomerMetaInformation(
        newCustomer.customerId
      );
      const success = result.success;
      if (success) {
        this.snackbarService.show(
          "Die Kundeninformationen wurden erfolgreich erfasst!",
          "success"
        );
      } else {
        this.snackbarService.show(
          "Bei der Erfassung der Kundeninformationen ist ein Fehler aufgetreten: " +
            result.error,
          "danger"
        );
      }
    }

    this.router.navigateByUrl("/mapAds/customers/show/" + this.data.customerId);
  }

  isHomepageChanged(newCustomer) {
    return (
      !this.data || this.data.customerHomepage != newCustomer.customerHomepage
    );
  }

  updateCustomerMetaInformation(customerId) {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(
        CustomerCrawlerAnimationDialogComponent,
        {
          width: "680px",
          panelClass: "no-spacing",
          disableClose: true,
          data: {
            customerId,
          },
        }
      );

      dialogRef.beforeClosed().subscribe((res) => {
        if (res) {
          resolve(res);
        }
      });
    });
  }
}
