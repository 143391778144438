import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectMenu } from "app/model/SelectMenu";

@Component({
  selector: "heat-map-sidebar-filter",
  templateUrl: "./heat-map-sidebar-filter.component.html",
  styleUrls: ["./heat-map-sidebar-filter.component.scss"],
})
export class HeatMapSidebarFilterComponent implements OnInit {
  @Input() useGoogleStatistics: boolean;
  @Input() useYoutubeStatistics: boolean;
  @Input() stores: any;
  @Input() goal: any = [];
  @Input() locationsStatistics: any;
  @Output() onSelectStore: EventEmitter<any> = new EventEmitter();
  @Output() onSelectZipcode: EventEmitter<any> = new EventEmitter();
  @Output() onSelectChannel: EventEmitter<any> = new EventEmitter();
  public tabs: any[];
  public activeTab: any;

  constructor() {
    this.tabs = [
      { label: "ZIPCODES", value: "Gebiete", icon: "place" },
      { label: "STORES", value: "Geschäfte", icon: "store" },
      { label: "SETTINGS", value: "Einstellungen", icon: "settings" },
    ];

    this.activeTab = this.tabs[0];
  }

  ngOnInit() {}

  onToggleChange(value) {
    this.activeTab = this.tabs.find((tab) => tab.label === value);
  }
}
