import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "time-period-calendar",
  templateUrl: "./time-period-calendar.component.html",
  styleUrls: ["./time-period-calendar.component.scss"],
})
export class TimePeriodCalendarComponent implements OnInit {
  @ViewChild("currentDate") currentDate: ElementRef;
  @ViewChild("days") days: ElementRef;
  @Input() date: string | Date;
  @Input() start: string | Date;
  @Input() end: string | Date;
  @Input() currYear: number;
  @Input() currMonth: number;
  public months = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];
  constructor() {}

  ngOnInit() {
    this.renderCalendar();
  }

  renderCalendar() {
    const date = new Date();

    let startDate = new Date(this.start);
    let endDate = new Date(this.end);

    const offset = startDate.getTimezoneOffset();

    startDate = new Date(startDate.getTime() - offset * 60000);
    endDate = new Date(endDate.getTime() - offset * 60000);

    startDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      0,
      0
    );

    endDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      0,
      0
    );

    const firstDayofMonth = new Date(this.currYear, this.currMonth, 1).getDay();
    const lastDateofMonth = new Date(
      this.currYear,
      this.currMonth + 1,
      0
    ).getDate();
    const lastDayofMonth = new Date(
      this.currYear,
      this.currMonth,
      lastDateofMonth
    ).getDay();

    let liTag = "";

    for (let i = firstDayofMonth; i > 0; i--) {
      liTag += `<li class="inactive day"></li>`;
    }

    for (let i = 1; i <= lastDateofMonth; i++) {
      let isToday =
        i === date.getDate() &&
        this.currMonth === new Date().getMonth() &&
        this.currYear === new Date().getFullYear()
          ? "active"
          : "";
      liTag += `<li class="${isToday} ${
        this.dateIsInTimePeriod(
          startDate,
          endDate,
          new Date(this.currYear, this.currMonth, i)
        )
          ? "date-in-time-period background-blue-dark-gray"
          : ""
      } ${
        this.dateIsTimePeriodStart(
          startDate,
          new Date(this.currYear, this.currMonth, i)
        )
          ? "date-is-time-period-start background-dark-blue-200"
          : ""
      }  ${
        this.dateIsTimePeriodEnd(
          endDate,
          new Date(this.currYear, this.currMonth, i)
        )
          ? "date-is-time-period-end background-dark-blue-200"
          : ""
      } day">${i}</li>`;
    }

    for (let i = lastDayofMonth; i < 6; i++) {
      liTag += `<li class="inactive day"></li>`;
    }

    this.currentDate.nativeElement.innerHTML = `${
      this.months[this.currMonth]
    } ${this.currYear}`;
    this.days.nativeElement.innerHTML = liTag;
  }

  dateIsInTimePeriod(startDate: Date, endDate: Date, currDate: Date) {
    return startDate < currDate && endDate > currDate;
  }

  dateIsTimePeriodStartOrEnd(startDate: Date, endDate: Date, currDate: Date) {
    return (
      startDate.getTime() === currDate.getTime() ||
      endDate.getTime() === currDate.getTime()
    );
  }

  dateIsTimePeriodStart(startDate: Date, currDate: Date) {
    return startDate.getTime() === currDate.getTime();
  }

  dateIsTimePeriodEnd(endDate: Date, currDate: Date) {
    return endDate.getTime() === currDate.getTime();
  }
}
