import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { base64ToFile } from "app/shared/utils/blob.utils";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";
import * as FileSaver from "file-saver";
import { MarketingPortalEditorDialogComponent } from "../../dialogs/marketing-portal/marketing-portal-editor-dialog/marketing-portal-editor-dialog.component";
import { MarketingPortalVideoEditorDialogComponent } from "../../dialogs/marketing-portal/marketing-portal-video-editor-dialog/marketing-portal-video-editor-dialog.component";
import { MPVideoStatus } from "app/model/marketing-portal/MPVideoStatus";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { PresalesItemSelectChannelDialogComponent } from "../../dialogs/presales-item-select-channel-dialog/presales-item-select-channel-dialog.component";
import { MPConfiguratorCreationType } from "app/model/marketing-portal/MPConfiguratorCreationType";
import { MPModule } from "app/model/marketing-portal/MPModule";
import { ModuleService } from "app/shared/services/module.service";

@Component({
  selector: "customer-media-asset-card",
  templateUrl: "./customer-media-asset-card.component.html",
  styleUrls: ["./customer-media-asset-card.component.scss"],
})
export class CustomerMediaAssetCardComponent implements OnInit {
  @Output() reload: EventEmitter<any> = new EventEmitter();
  @Input() mediaAsset: any;
  @Input() channels: MPChannel[];
  @Input() customerId: number;
  @Input() customerLogo: string;

  public mediaAssetChannels: MPChannel[];
  public frames: any[] = [];
  public currentFrameIndex: number = null;
  public readonly environment = environment;
  public showSkeletonAnimation: boolean = true;
  public skeletonAnimationTheme: object;
  public readinessInterval = null;
  public canBeDownloaded: boolean;
  public mpChannel = MPChannel;

  readonly MODULE_SERVICE_PRESALES = MPModule.MODULE_SERVICE_PRESALES;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private crudService: CrudService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService,
    public moduleService: ModuleService
  ) {}

  async ngOnInit() {
    this.setSkeletonTheme();
    this.filterChannels();
    this.updateReadiness();
    this.setCanBeDownloaded();
  }

  filterChannels() {
    this.mediaAssetChannels = this.channels.filter((channel: MPChannel) =>
      this.mediaAsset.channelIds.includes(channel.channelId)
    );
  }

  setCanBeDownloaded() {
    let flag: boolean = false;
    this.mediaAssetChannels.forEach((channel: MPChannel) => {
      if (
        (this.environment.partnerId == 9 || this.environment.partnerId == 17) &&
        channel.channelId === this.mpChannel.CHANNEL_ID_ADDRESSABLE_TV
      )
        flag = true;
    });

    this.canBeDownloaded = flag;
  }

  async download() {
    try {
      this.appLoader.open();

      let channelId = this.mediaAssetChannels[0].channelId;

      let base64 = await this.crudService
        .downloadBase64MediaAsset(this.mediaAsset.ImageId, channelId)
        .toPromise();
      let blob = base64ToFile(base64);

      const fileName = `download.${blob.type.split("/")[1]}`;

      FileSaver.saveAs(blob, fileName);
    } catch (exc) {
      this.snackbarService.show(exc.error, "danger");
    } finally {
      this.appLoader.close();
    }
  }

  edit() {
    const { ImageId, ImageHeight, ImageWidth, isVideo, TemplateId } =
      this.mediaAsset;

    const mediaAsset: MPMediaAsset = new MPMediaAsset(
      ImageId,
      "",
      ImageWidth,
      ImageHeight,
      null,
      null,
      false,
      !isVideo,
      isVideo,
      true,
      null,
      null,
      null,
      null,
      false,
      TemplateId
    );

    const dialogComponents = isVideo
      ? MarketingPortalVideoEditorDialogComponent
      : MarketingPortalEditorDialogComponent;
    const dialogRef = this.dialog.open(dialogComponents, {
      width: "100vw",
      height: "auto",
      maxWidth: "100vw",
      maxHeight: "auto",
      panelClass: "full-screen-dialog",
      data: {
        customerId: this.customerId,
        customerLogo: this.customerLogo,
        mediaAsset: mediaAsset,
        isEdit: true,
        channelId: null,
        resolutionId: null,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.reload.emit();
      }
    });
  }

  getAllChannelIds(): string {
    return this.mediaAssetChannels
      .map((channel) => channel.channelId)
      .join(",");
  }

  createCampaign() {
    let channelIds: string = this.getAllChannelIds();
    let path = `/mapAds/marketing-portal/configurator?creationType=${MPConfiguratorCreationType.CAMPAIGN}&returnUrl=${this.router.url}&customerId=${this.customerId}&mediaAssetIds=${this.mediaAsset.ImageId}&channelIds=${channelIds}`;
    this.router.navigateByUrl(path, { replaceUrl: true });
  }

  updateReadiness() {
    if (this.mediaAsset.hasOwnProperty("isReady") && !this.mediaAsset.isReady) {
      const self = this;
      this.readinessInterval = setInterval(function () {
        self.crudService
          .getVideoStatusByMediaAssetId(self.mediaAsset.ImageId)
          .subscribe(async (res: MPVideoStatus) => {
            if (!res || res.status == "failed") {
              clearInterval(self.readinessInterval);
              return;
            }
            if (res.isReady) {
              await self.delay(1500);
              self.mediaAsset.isReady = res.isReady;
              clearInterval(self.readinessInterval);
            }
          });
      }, 3000);
    }
  }

  async addToPresales() {
    const dialogRef = this.dialog.open(
      PresalesItemSelectChannelDialogComponent,
      {
        width: "680px",
        panelClass: "no-spacing",
        maxHeight: "95vh",
        disableClose: true,
        data: {
          channels: this.mediaAssetChannels,
        },
      }
    );

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        this.appLoader.open();
        const { channelId } = res;

        let data = [channelId];

        await this.crudService
          .addItemToPresales(this.mediaAsset.ImageId, this.customerId, data)
          .toPromise()
          .then((res) => {
            this.snackbarService.show(
              "Das Werbemittel wurde erfolgreich in den Presales-Ordner verschoben.",
              "success"
            );
          })
          .catch((e) => {
            this.snackbarService.show(e.error, "danger");
          });

        this.appLoader.close();
      }
    });
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  loadImage() {
    this.showSkeletonAnimation = false;
  }

  loadedVideoMetaData() {
    this.showSkeletonAnimation = false;
  }

  setSkeletonTheme() {
    this.skeletonAnimationTheme = {
      width: "120px",
      height: "120px",
      "margin-bottom": 0,
    };
  }
}
