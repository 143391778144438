import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ApprovalElementCommentDialogComponent } from "../../dialogs/approval-element-comment-dialog/approval-element-comment-dialog.component";
import { CrudService } from "app/views/mapAds/crud.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { PresalesItem } from "app/model/presales/PresalesItem";

@Component({
  selector: "presales-presentation-feedback",
  templateUrl: "./presales-presentation-feedback.component.html",
  styleUrls: ["./presales-presentation-feedback.component.scss"],
})
export class PresalesPresentationFeedbackComponent implements OnInit {
  @Input() presalesItem: PresalesItem;
  public comment: string;
  public rating: number;

  constructor(
    private dialog: MatDialog,
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  init() {
    this.comment = this.presalesItem.comment;

    if (this.presalesItem.hasDislike) {
      this.rating = -1;
    } else {
      if (this.presalesItem.hasLike) {
        this.rating = 1;
      } else {
        this.rating = 0;
      }
    }
  }

  updateRating(rating) {
    const prevValue = this.rating;
    this.rating = prevValue === rating ? 0 : rating;

    this.crudService
      .setLikeToPresales(this.presalesItem.preSalesId, this.rating)
      .subscribe(
        (res) => {
          this.snackbarService.show(
            "Die Bewertung wurde erfolgreich gespeichert."
          );

          this.presalesItem.hasLike = this.rating === 1;
          this.presalesItem.hasDislike = this.rating === -1;
        },
        (error) => {
          this.snackbarService.show(
            "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut",
            "danger"
          );
          this.rating = prevValue;
        }
      );
  }

  openComment() {
    const dialogRef = this.dialog.open(ApprovalElementCommentDialogComponent, {
      width: "680px",
      panelClass: "no-spacing",
      maxHeight: "95vh",
      disableClose: true,
      data: {
        comment: this.comment
          ? {
              note: this.comment,
              proposal: "",
            }
          : null,
        type: "text",
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const prevValue = this.comment;
        this.comment = res.value ? res.value.note : null;

        this.crudService
          .addCommentToPresales(this.presalesItem.preSalesId, this.comment)
          .subscribe(
            (res) => {
              this.snackbarService.show(
                "Der Kommentar wurde erfolgreich gespeichert."
              );

              this.presalesItem.comment = this.comment;
            },
            (error) => {
              this.snackbarService.show(
                "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut",
                "danger"
              );
              this.comment = prevValue;
            }
          );
      }
    });
  }
}
