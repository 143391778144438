import { Injectable } from "@angular/core";
import { MPModule } from "app/model/marketing-portal/MPModule";
import { BehaviorSubject } from "rxjs";
import { ModuleService } from "./module.service";
import { MPConfiguratorCreationType } from "app/model/marketing-portal/MPConfiguratorCreationType";
import { Router } from "@angular/router";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  menuItems: BehaviorSubject<IMenuItem[]>;
  menuItems$: any;
  defaultMenu: IMenuItem[];
  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "Frequently Accessed";
  constructor(public moduleService: ModuleService, private router: Router) {
    this.menuItems = new BehaviorSubject<IMenuItem[]>(this.genDefaultMenu());
    this.menuItems$ = this.menuItems.asObservable();
  }

  publishNavigationChange(data = []) {
    this.defaultMenu = this.genDefaultMenu();
    this.menuItems.next(this.defaultMenu);
  }

  genDefaultMenu() {
    let defaultMenu = [];

    defaultMenu.push({
      name: "Kampagnen",
      type: "dropDown",
      tooltip: "Kampagnen",
      icon: "diamond",
      state: "mapAds/marketing-portal",
      sub: [
        { name: "MyCampaigns", state: "campaigns" },
        {
          name: "CreateCampaign",
          state: "configurator",
        },
      ],
    });

    if (this.moduleService.hasAccess([MPModule.MODULE_SERVICE_CUSTOMER])) {
      defaultMenu.push({
        name: "Kunden",
        type: "link",
        tooltip: "Kunden",
        icon: "account_circle",
        state: "mapAds/customers",
      });
    }

    if (
      this.moduleService.hasAccess([
        MPModule.MODULE_SERVICE_CUSTOMER,
        MPModule.MODULE_SERVICE_APPROVAL,
      ])
    ) {
      defaultMenu.push({
        name: "Freigaben",
        type: "link",
        tooltip: "Freigaben",
        icon: "approval",
        state: "mapAds/approvals",
      });
    }

    if (this.moduleService.isEnduser()) {
      defaultMenu.push({
        name: "Werbeprofil",
        type: "link",
        tooltip: "Werbeprofil",
        icon: "account_circle",
        state: "mapAds/profile",
      });
    }

    defaultMenu.push({
      name: "Bestellungen",
      type: "link",
      tooltip: "Bestellungen",
      icon: "credit_card",
      state: "mapAds/payments",
    });

    defaultMenu.push({
      name: "SETTINGS",
      type: "link",
      tooltip: "Statistiken",
      icon: "settings",
      state: "mapAds/settings/general",
      // sub: [
      //   { name: "ACCOUNTS", state: "accounts" },
      //   { name: "GENERAL", state: "general" },
      // ],
    });

    // if (this.moduleService.hasAccess([MPModule.MODULE_SERVICE_PRESALES])) {
    //   defaultMenu.push({
    //     name: "Präsentation starten",
    //     type: "link",
    //     tooltip: "Freigaben",
    //     icon: "play_circle_filled",
    //     state: "mapAds/presales/presentation",
    //   });
    // }

    return defaultMenu;
  }
}
