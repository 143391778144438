import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPVideoStatus } from "app/model/marketing-portal/MPVideoStatus";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";
import * as FileSaver from "file-saver";
import { MatDialog } from "@angular/material";
import { MarketingPortalEditorDialogComponent } from "../../dialogs/marketing-portal/marketing-portal-editor-dialog/marketing-portal-editor-dialog.component";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { Router } from "@angular/router";
import { MarketingPortalVideoEditorDialogComponent } from "../../dialogs/marketing-portal/marketing-portal-video-editor-dialog/marketing-portal-video-editor-dialog.component";
import { base64ToFile } from "app/shared/utils/blob.utils";
import { MediaAssetExternalSource } from "app/model/marketing-portal/MediaAssetExternalSource";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";

@Component({
  selector: "quick-media-asset-creation",
  templateUrl: "./quick-media-asset-creation.component.html",
  styleUrls: ["./quick-media-asset-creation.component.scss"],
})
export class QuickMediaAssetCreationComponent implements OnInit {
  @ViewChild("video") video: ElementRef;
  @Output() onDone: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() goBack: EventEmitter<any> = new EventEmitter();
  @Input() isImage: boolean;
  @Input() template: any;
  @Input() customerId: number;
  @Input() channels: MPChannel[];
  @Input() type: number = null; // 1 = Automatic, 2 = Manual
  public loadingTexts: string[] = [
    "Künstliche Intelligenz erfasst Unternehmensinformationen...",
    "Künstliche Intelligenz erstellt Texte...",
    "Künstliche Intelligenz erstellt Bilder...",
    "Künstliche Intelligenz generiert Werbemittel...",
  ];
  public loadingTextIndex: number = 0;
  public loadingInterval: any;
  public animationHoldTime: number = 1000; // ms
  public showAnimation: boolean = false;
  public mediaAssetId: number = null;
  public showPreviewImageLoading: boolean = false;
  public videoProcessingCheckInterval: any;
  public readonly environment = environment;
  public imageWidth: number = 600;
  public imageHeight: number = 600;
  public isLoadingDownload: boolean = false;
  public mediaAssetExternalSources: MediaAssetExternalSource[] = [];
  public mpChannel = MPChannel;
  public canBeDownloaded: boolean = false;
  constructor(
    private crudService: CrudService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private router: Router,
    private appLoader: AppLoaderService
  ) {}

  async ngOnInit() {
    this.setCanBeDownloaded();
  }

  setCanBeDownloaded() {
    let flag: boolean = false;
    this.channels.forEach((channel: MPChannel) => {
      if (
        (this.environment.partnerId == 9 || this.environment.partnerId == 17) &&
        (channel.channelId === this.mpChannel.CHANNEL_ID_ADDRESSABLE_TV ||
          !this.isImage)
      )
        flag = true;
    });

    this.canBeDownloaded = flag;
  }

  async startMediaAssetGenerationProcess() {
    this.showAnimation = true;
    await this.delay(500);

    const self = this;
    this.loadingInterval = setInterval(function () {
      if (self.loadingTextIndex + 2 > self.loadingTexts.length) {
        clearInterval(self.loadingInterval);
        self.generateMediaAsset();
        return;
      }
      self.loadingTextIndex++;
    }, this.animationHoldTime);
  }

  onSelectType(type: number) {
    this.type = type;

    if (type === 1) {
      this.startMediaAssetGenerationProcess();
      return;
    }

    this.openEditor(null, false, true, true, this.template.templateId);
  }

  imageLoad() {
    this.showPreviewImageLoading = false;
  }

  reloadVideo() {
    this.video.nativeElement.src = `${environment.imageServerUrl}api/Public/GetMediaAsset?id=${this.mediaAssetId}`;
    this.video.nativeElement.load();
  }

  waitUntilVideoIsGenerated(mediaAssetId: number) {
    return new Promise((resolve, reject) => {
      const maxRecurrences = 15;
      let counter = 0;
      const self = this;
      this.showPreviewImageLoading = true;
      this.videoProcessingCheckInterval = setInterval(function () {
        counter++;
        self.crudService
          .getVideoStatusByMediaAssetId(mediaAssetId)
          .subscribe(async (res: MPVideoStatus) => {
            if (res.isReady) {
              clearInterval(self.videoProcessingCheckInterval);
              self.showPreviewImageLoading = false;
              resolve(true);
            }

            if (res.status == "failed" || counter > maxRecurrences) {
              clearInterval(self.videoProcessingCheckInterval);
              self.showPreviewImageLoading = false;
              reject();
            }
          });
      }, 1500);
    });
  }

  async download() {
    if (this.showPreviewImageLoading) {
      return;
    }

    try {
      this.isLoadingDownload = true;
      this.appLoader.open();

      let channelId = this.channels[0].channelId;

      let base64 = await this.crudService
        .downloadBase64MediaAsset(this.mediaAssetId, channelId)
        .toPromise();
      let blob = base64ToFile(base64);

      const fileName = `download.${blob.type.split("/")[1]}`;

      FileSaver.saveAs(blob, fileName);
    } catch (exc) {
      this.snackbarService.show(exc.error, "danger");
    } finally {
      this.appLoader.close();
      this.isLoadingDownload = false;
    }
  }

  openEditor(
    mediaAsset: MPMediaAsset = null,
    edit = false,
    typeSelectionIfError = false,
    final = false,
    preSelectTemplateId = null
  ) {
    const dialogComponent = this.isImage
      ? MarketingPortalEditorDialogComponent
      : MarketingPortalVideoEditorDialogComponent;

    const dialogRef = this.dialog.open(dialogComponent, {
      width: "100vw",
      height: "auto",
      maxWidth: "100vw",
      maxHeight: "auto",
      panelClass: "full-screen-dialog",
      data: {
        customerId: this.customerId,
        mediaAsset: mediaAsset,
        isEdit: edit,
        channelId: null,
        resolutionId: null,
        preSelectTemplateId,
      },
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        if (final) {
          this.onDone.emit();
        }

        this.mediaAssetId = this.isImage
          ? res.templateMediaAssets.templateMediaAssets[0].imageId
          : res.templateMediaAsset.mediaAssetId;

        if (!this.isImage) {
          await this.waitUntilVideoIsGenerated(this.mediaAssetId).catch(
            (error) => {
              // this.snackbarService.show(
              //   "Bei der Generierung des Videos ist ein unerwarteter Fehler aufgetreten",
              //   "danger"
              // );
            }
          );
          this.reloadVideo();
        }

        this.getMediaAssetExternalSources();
      } else if (typeSelectionIfError) {
        this.type = null;
      }
    });
  }

  edit() {
    const mediaAsset: MPMediaAsset = new MPMediaAsset(
      this.mediaAssetId,
      "",
      600,
      600,
      null,
      null,
      false,
      this.isImage,
      !this.isImage,
      true,
      null,
      null,
      null,
      null,
      false,
      this.template.templateId
    );

    this.openEditor(mediaAsset, true);
  }

  getAllChannelIds(): string {
    return this.channels.map((channel) => channel.channelId).join(",");
  }

  createCampaign() {
    let channelIds: string = this.getAllChannelIds();
    let path = `/mapAds/marketing-portal/campaign/channels?customer=${this.customerId}&mediaAsset=${this.mediaAssetId}&channels=${channelIds}`;

    this.onClose.emit();
    this.router.navigateByUrl(path);
  }

  generateMediaAsset() {
    if (!this.isImage) {
      this.generateVideoMediaAsset();
      return;
    }

    this.generateImageMediaAsset();
  }

  async generateImageMediaAsset() {
    const { templateId, templateExternalId, templateConfigModel } =
      this.template;

    const {
      globalColorCode,
      modifications,
      FontFamily,
      FontWeight,
      isNewBanner,
    } = templateConfigModel;

    const data = {
      templateId,
      template: templateExternalId,
      hasGlobalColor: false,
      globalColorCode,
      modifications,
      FontFamily,
      FontWeight,
      isNewBanner,
    };

    await this.crudService
      .createTemplateBasedImage(null, data, this.customerId, null)
      .toPromise()
      .then(async (res: any) => {
        if (!res) {
          this.snackbarService.show(
            "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut oder nimm Kontakt mit uns auf!",
            "danger"
          );
          return;
        }

        this.mediaAssetId = res[0].imageId;
        this.getMediaAssetExternalSources();
        this.showPreviewImageLoading = true;
        this.showAnimation = false;
        this.onDone.emit();
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });
  }

  async generateVideoMediaAsset() {
    const { templateId, templateConfigModel } = this.template;

    const data = {
      templateId,
      templateConfigModel,
    };

    await this.crudService
      .createTemplateBasedVideo(data, this.customerId, null)
      .toPromise()
      .then((res: any) => {
        this.mediaAssetId = res;
        this.getMediaAssetExternalSources();
        const self = this;
        const maxRecurrences = 15;
        let counter = 0;
        this.videoProcessingCheckInterval = setInterval(function () {
          counter++;
          self.crudService
            .getVideoStatusByMediaAssetId(self.mediaAssetId)
            .subscribe(async (res: MPVideoStatus) => {
              if (res.isReady) {
                clearInterval(self.videoProcessingCheckInterval);
                self.showAnimation = false;
                self.onDone.emit();
              }

              if (res.status == "failed" || counter > maxRecurrences) {
                clearInterval(self.videoProcessingCheckInterval);
                // self.snackbarService.show(
                //   "Bei der Generierung des Videos ist ein unerwarteter Fehler aufgetreten",
                //   "danger"
                // );
              }
            });
        }, 1500);
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });
  }

  getMediaAssetExternalSources() {
    this.crudService
      .getExternalSourcesFromMediaAssetId(this.mediaAssetId)
      .subscribe(
        (res) => {
          this.mediaAssetExternalSources = res;
        },
        (error) => {
          this.snackbarService.show(error.error, "danger");
        }
      );
  }

  delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
}
