import { Platform } from "@angular/cdk/platform";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { PopupBlockedDialogComponent } from "../../dialogs/popup-blocked-dialog/popup-blocked-dialog.component";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { MatDialog, MatSlideToggle } from "@angular/material";
import { SavedPaymentMethod } from "app/model/payment/SavedPaymentMethod";
import { BudgetBreakDown } from "app/model/payment/BudgetBreakDown";
import { PaymentResult } from "app/model/payment/PaymentResult";
import { Router } from "@angular/router";
import { PaymentResultStatus } from "app/model/payment/PaymentResultStatus";

@Component({
  selector: "payment-action-box",
  templateUrl: "./payment-action-box.component.html",
  styleUrls: ["./payment-action-box.component.scss"],
})
export class PaymentActionBoxComponent implements OnInit {
  @ViewChild("slideToggle") slideToggle: MatSlideToggle;
  @Output() onChangePaymentAmount: EventEmitter<any> = new EventEmitter();
  @Output() onConfirmPayment: EventEmitter<any> = new EventEmitter();
  @Input() mpCampaignId: number;
  @Input() paymentMethods: SavedPaymentMethod[];
  @Input() checkoutNeeded: boolean;
  public storePaymentMethod: boolean = false;
  public selectedPaymentMethod: SavedPaymentMethod = null;
  public couponCode: string;
  public budgetBreakDown: BudgetBreakDown = null;
  public initBudgetBreakDown: BudgetBreakDown = null;
  public activatedCoupon: string;
  constructor(
    private appLoader: AppLoaderService,
    private crudService: CrudService,
    private platform: Platform,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  async ngOnInit() {
    setTimeout(() => this.appLoader.open());

    await this.crudService
      .getMpCampaignPaymentBreakdown(this.mpCampaignId, null)
      .toPromise()
      .then((res) => {
        this.budgetBreakDown = res;
        this.initBudgetBreakDown = res;
        this.onChangePaymentAmount.emit(this.budgetBreakDown);
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    setTimeout(() => this.appLoader.close());
  }

  changeStorePaymentMethod($event) {
    this.storePaymentMethod = $event.checked;
  }

  selectPaymentMethod(paymentMethod: SavedPaymentMethod) {
    this.selectedPaymentMethod =
      !this.selectedPaymentMethod ||
      this.selectedPaymentMethod.Method != paymentMethod.Method
        ? paymentMethod
        : null;

    this.storePaymentMethod = false;
  }

  onToggleStorePaymentMethod($event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.slideToggle.toggle();
    this.storePaymentMethod = this.slideToggle.checked;
  }

  redeemCoupon() {
    this.appLoader.open();

    this.crudService
      .getMpCampaignPaymentBreakdown(this.mpCampaignId, this.couponCode)
      .subscribe(
        (data: any) => {
          this.budgetBreakDown = data;
          this.activatedCoupon = this.couponCode;
          this.couponCode = null;
          this.onChangePaymentAmount.emit(this.budgetBreakDown);
        },
        (error) => {
          this.appLoader.close();
          this.snackbarService.show(error.error, "danger");
        },
        () => {
          this.appLoader.close();
        }
      );
  }

  removeCoupon() {
    this.activatedCoupon = null;
    this.onChangePaymentAmount.emit(this.initBudgetBreakDown);
  }

  makePayment() {
    if (this.selectedPaymentMethod) {
      this.makePaymentFromSavedSource();
      return;
    }

    this.makeDefaulPayment();
  }

  async confirmPayment() {
    this.appLoader.open();

    await this.crudService
      .confirmPaymentForMpCampaign(this.mpCampaignId, this.activatedCoupon)
      .toPromise()
      .then((res) => {
        this.appLoader.close();
        if (res) {
          this.onConfirmPayment.emit();
        } else {
          this.snackbarService.show(
            "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es erneut!",
            "danger"
          );
        }
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
        this.appLoader.close();
      });
  }

  makeDefaulPayment() {
    this.appLoader.open();

    this.crudService
      .createMpCampaignPayment(
        this.mpCampaignId,
        this.storePaymentMethod,
        this.activatedCoupon
      )
      .subscribe(
        (data: PaymentResult) => {
          if (
            data &&
            data.Status === PaymentResultStatus.PAYMENT_STATIS_SUCCESS
          ) {
            if (data.URL) {
              const newWindow = window.open(data.URL, "_blank");
              if (
                !newWindow ||
                newWindow.closed ||
                typeof newWindow.closed == "undefined"
              ) {
                if (this.platform.FIREFOX || this.platform.SAFARI) {
                  // Popup blocked
                  this.dialog.open(PopupBlockedDialogComponent, {
                    width: "1000px",
                  });
                }
              }
            } else {
              this.snackbarService.show(data.Message, "info");
            }
          } else {
            var message =
              data && data.Message ? data.Message : "Keine Anpassung notwendig";

            this.snackbarService.show(message, "info");
          }
        },
        (error) => {
          this.appLoader.close();
          this.snackbarService.show(error.error, "danger");
        },
        () => {
          this.appLoader.close();
        }
      );
  }

  makePaymentFromSavedSource() {
    this.appLoader.open();

    this.crudService
      .createMpCampaignPaymentFromSavedSource(
        this.mpCampaignId,
        this.selectedPaymentMethod.InternalName,
        this.activatedCoupon
      )
      .subscribe(
        (result: PaymentResult) => {
          if (result.Status === PaymentResultStatus.PAYMENT_STATIS_SUCCESS) {
            const url = result.URL;
            const isExternalURL = new URL(url).origin !== location.origin;

            if (isExternalURL) {
              const newWindow = window.open(result.URL, "_blank");
              if (
                !newWindow ||
                newWindow.closed ||
                typeof newWindow.closed == "undefined"
              ) {
                if (this.platform.FIREFOX || this.platform.SAFARI) {
                  // Popup blocked
                  this.dialog.open(PopupBlockedDialogComponent, {
                    width: "1000px",
                  });
                }
              }
              return;
            }

            window.location.href = result.URL;
            return;
          }

          this.snackbarService.show(result.Message, "danger");
        },
        (error) => {
          this.appLoader.close();
          this.snackbarService.show(error.error, "danger");
        },
        () => {
          this.appLoader.close();
        }
      );
  }
}
