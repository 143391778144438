import { Component, OnInit } from "@angular/core";
import { SavedPaymentMethod } from "app/model/payment/SavedPaymentMethod";
import { AppConfirmService } from "app/shared/services/app-confirm/app-confirm.service";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "payment-methods-settings",
  templateUrl: "./payment-methods-settings.component.html",
  styleUrls: ["./payment-methods-settings.component.scss"],
})
export class PaymentMethodsSettingsComponent implements OnInit {
  public paymentMethods: SavedPaymentMethod[];

  constructor(
    private crudService: CrudService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService,
    private confirmService: AppConfirmService
  ) {}

  ngOnInit() {
    this.getSavedPaymentMethods();
  }

  async getSavedPaymentMethods() {
    setTimeout(() => this.appLoader.open());

    await this.crudService
      .getUserSavedPaymentMethods()
      .toPromise()
      .then((res) => {
        this.paymentMethods = res;
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    setTimeout(() => this.appLoader.close());
  }

  async deleteSavedPaymentMethod(paymentMethod: SavedPaymentMethod) {
    this.confirmService
      .confirm({
        message: `Willst du die Zahlungsmethode <span class="text-semi-bold">"${paymentMethod.Method}"</span> wirklich löschen?`,
        title: "Zahlungsmethode löschen",
      })
      .subscribe(async (res) => {
        if (res) {
          this.appLoader.open();

          await this.crudService
            .deleteUserSavedPaymentMethod(paymentMethod.InternalName)
            .toPromise()
            .then((res) => {
              this.snackbarService.show(
                "Die gespeicherte Zahlungsmethode wurde erfolgreich entfernt!",
                "success"
              );
              this.getSavedPaymentMethods();
            })
            .catch((e) => {
              this.snackbarService.show(e.error, "danger");
            });

          this.appLoader.close();
        }
      });
  }
}
