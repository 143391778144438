import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MPVideoStatus } from "app/model/marketing-portal/MPVideoStatus";
import { MPMediaAsset } from "app/model/media-asset/MPMediaAsset";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";

@Component({
  selector: "media-asset-card",
  templateUrl: "./media-asset-card.component.html",
  styleUrls: ["./media-asset-card.component.scss"],
})
export class MediaAssetCardComponent implements OnInit {
  @ViewChild("video") video: ElementRef;
  @Input() mediaAsset: MPMediaAsset;
  @Input() size?: number = 220;
  public videoProcessingCheckInterval: any;
  public showPreviewImageLoading: boolean = false;
  public environment = environment;
  constructor(
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("mediaAsset")) {
      this.reload();
    }
  }

  async reload() {
    if (!this.mediaAsset.isImage) {
      await this.waitUntilVideoIsGenerated(this.mediaAsset.mediaAssetId).catch(
        (error) => {
          // this.snackbarService.show(
          //   "Bei der Generierung des Videos ist ein unerwarteter Fehler aufgetreten",
          //   "danger"
          // );
        }
      );
      this.reloadVideo();
    }
  }

  reloadVideo() {
    this.video.nativeElement.src = `${environment.imageServerUrl}api/Public/GetMediaAsset?id=${this.mediaAsset.mediaAssetId}`;
    this.video.nativeElement.load();
  }

  waitUntilVideoIsGenerated(mediaAssetId: number) {
    return new Promise((resolve, reject) => {
      const maxRecurrences = 15;
      let counter = 0;
      const self = this;
      this.showPreviewImageLoading = true;
      this.videoProcessingCheckInterval = setInterval(function () {
        counter++;
        self.crudService
          .getVideoStatusByMediaAssetId(mediaAssetId)
          .subscribe(async (res: MPVideoStatus) => {
            if (res.isReady) {
              clearInterval(self.videoProcessingCheckInterval);
              self.showPreviewImageLoading = false;
              resolve(true);
            }

            if (res.status == "failed" || counter > maxRecurrences) {
              clearInterval(self.videoProcessingCheckInterval);
              self.showPreviewImageLoading = false;
              reject();
            }
          });
      }, 1500);
    });
  }
}
