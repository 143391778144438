import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChange,
  ViewChild,
} from "@angular/core";
import { MPVideoStatus } from "app/model/marketing-portal/MPVideoStatus";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";

@Component({
  selector: "campaign-creative-tv-preview",
  templateUrl: "./campaign-creative-tv-preview.component.html",
  styleUrls: ["./campaign-creative-tv-preview.component.scss"],
})
export class CampaignCreativeTvPreviewComponent implements OnInit {
  @ViewChild("video") video: ElementRef;
  @Input() mediaAssetUrl: string;
  @Input() isImage: boolean;
  @Input() mediaAssetId?: any;
  public videoProcessingCheckInterval: any;
  public showPreviewImageLoading: boolean = false;
  constructor(
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  async ngOnChanges(simpleChange: SimpleChange) {
    if (this.mediaAssetId) {
      this.showPreviewImageLoading = true;

      await this.waitUntilVideoIsGenerated(this.mediaAssetId).catch((error) => {
        // this.snackbarService.show(
        //   "Bei der Generierung des Videos ist ein unerwarteter Fehler aufgetreten",
        //   "danger"
        // );
      });
      this.reloadVideo();
    }
  }

  async ngOnInit() {}

  reloadVideo() {
    this.video.nativeElement.src = `${environment.imageServerUrl}api/Public/GetMediaAsset?id=${this.mediaAssetId}`;
    this.video.nativeElement.load();
  }

  waitUntilVideoIsGenerated(mediaAssetId: number) {
    return new Promise((resolve, reject) => {
      const maxRecurrences = 15;
      let counter = 0;
      const self = this;
      this.showPreviewImageLoading = true;
      this.videoProcessingCheckInterval = setInterval(function () {
        counter++;
        self.crudService
          .getVideoStatusByMediaAssetId(mediaAssetId)
          .subscribe(async (res: MPVideoStatus) => {
            if (res.isReady) {
              clearInterval(self.videoProcessingCheckInterval);
              self.showPreviewImageLoading = false;
              resolve(true);
            }

            if (res.status == "failed" || counter > maxRecurrences) {
              clearInterval(self.videoProcessingCheckInterval);
              self.showPreviewImageLoading = false;
              reject();
            }
          });
      }, 1500);
    });
  }
}
