import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { MPCustomer } from "app/model/marketing-portal/MPCustomer";
import { PresalesItem } from "app/model/presales/PresalesItem";
import { PresalesItemExpandedDialogComponent } from "../../dialogs/presales-item-expanded-dialog/presales-item-expanded-dialog.component";
import * as channelInformation from "../../../../data/PresalesChannelInformation.json";

@Component({
  selector: "ma-presales-presentation-slide",
  templateUrl: "./presales-presentation-slide.component.html",
  styleUrls: ["./presales-presentation-slide.component.scss"],
  animations: [
    trigger("logoAnimation", [
      transition("* => *", [
        animate(
          "1s",
          keyframes([
            style({ opacity: 0, left: "-75px" }),
            style({ opacity: 1, left: "25px" }),
          ])
        ),
      ]),
    ]),
    trigger("previewAnimation", [
      transition("* => *", [
        animate(
          "1s",
          keyframes([
            style({ opacity: 0, bottom: "100px" }),
            style({ opacity: 1, bottom: "0" }),
          ])
        ),
      ]),
    ]),
    trigger("bgShapeAnimation", [
      transition("* => *", [
        animate(
          "1s",
          keyframes([
            style({ opacity: 0, left: "-200px" }),
            style({ opacity: 1, left: "-100px" }),
          ])
        ),
      ]),
    ]),
    trigger("contentAnimation", [
      transition("* => *", [
        animate(
          "1s",
          keyframes([
            style({ opacity: 0.2, left: "100px" }),
            style({ opacity: 1, left: "0" }),
          ])
        ),
      ]),
    ]),
  ],
})
export class PresalesPresentationSlideComponent implements OnInit {
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Input() presalesItem: PresalesItem;
  @Input() customer: MPCustomer;
  @Input() currentSlideIndex: number;
  public currentMediaAssetIndex: number = 0;
  public presalesChannelInformation = channelInformation["default"];

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.currentMediaAssetIndex = 0;
  }

  next() {
    if (
      this.currentMediaAssetIndex >=
      this.presalesItem.mediaAssets.length - 1
    ) {
      this.currentMediaAssetIndex = 0;
      return;
    }

    this.currentMediaAssetIndex++;
  }

  back() {
    if (this.currentMediaAssetIndex === 0) {
      this.currentMediaAssetIndex = this.presalesItem.mediaAssets.length - 1;
      return;
    }

    this.currentMediaAssetIndex--;
  }

  zoom() {
    this.dialog.open(PresalesItemExpandedDialogComponent, {
      width: "95vw",
      height: "95vh",
      panelClass: "no-spacing",
      data: {
        presalesItem: this.presalesItem,
        mediaAsset: this.presalesItem.mediaAssets[this.currentMediaAssetIndex],
      },
    });
  }
}
