import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SelectMenu } from "app/model/SelectMenu";
import { ColorService } from "app/shared/services/color.service";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Color } from "ng2-charts";

@Component({
  selector: "mc-impressions-statistics",
  templateUrl: "./mc-impressions-statistics.component.html",
  styleUrls: ["./mc-impressions-statistics.component.scss"],
})
export class McImpressionsStatisticsComponent implements OnInit {
  @Input() impressionStatistics;
  @Input() activateFilter: boolean = true;
  @Output() setImpressionsType: EventEmitter<any> = new EventEmitter();
  public timeMenuItems: SelectMenu[] = [];
  public channelMenuItems: SelectMenu[] = [];
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: string[] = [];
  public lineChartOptions: ChartOptions = {
    responsiveAnimationDuration: 500,
    animation: {
      animateScale: true,
      animateRotate: true,
      duration: 500,
      easing: "linear",
    },
    devicePixelRatio: 2,
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return "";
        },
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return (
            data.datasets[tooltipItem.datasetIndex].label +
            ": " +
            tooltipItem.yLabel.toLocaleString()
          );
        },
      },
    },
    scales: {
      xAxes: [
        {
          stacked: false,
          ticks: {
            fontColor: "#aaacb9",
            fontFamily: "'Roboto'",
          },
          gridLines: {
            color: "#f0f0f0",
          },
        },
      ],
      yAxes: [
        {
          stacked: false,
          ticks: {
            fontColor: "#aaacb9",
            fontFamily: "'Roboto'",
            min: 0,
            beginAtZero: true,
            callback: function (label, index, labels) {
              return label.toLocaleString();
            },
          },
          gridLines: {
            color: "#f0f0f0",
          },
        },
      ],
    },
  };
  public lineChartColors: Color[] = [];
  public lineChartLegend = false;
  public lineChartType: ChartType = "line";
  constructor(
    private translate: TranslateService,
    private colorService: ColorService
  ) {}

  async ngOnInit() {
    this.setLineColors();
    this.initFilters();
    this.setData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty("impressionStatistics") &&
      !changes.impressionStatistics.firstChange
    ) {
      this.setData();
    }
  }

  setLineColors() {
    let primaryColor = this.colorService.getPrimaryColor();
    let primaryBackgroundColor = this.colorService.RGBToRGBA(primaryColor, 0.2);
    let pointHoverBorderColor = this.colorService.RGBToRGBA(primaryColor, 0.8);

    this.lineChartColors = [
      {
        backgroundColor: primaryBackgroundColor,
        borderColor: primaryColor,
        pointBackgroundColor: primaryColor,
        pointBorderColor: primaryColor,
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: pointHoverBorderColor,
      },
      {
        backgroundColor: "rgba(131, 183, 251, 0.2)",
        borderColor: "#83b7fb",
        pointBackgroundColor: "#83b7fb",
        pointBorderColor: "#83b7fb",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(148,159,177,0.8)",
      },
    ];
  }

  initFilters() {
    this.initTimeFilter();
    this.initChannelFilter();
  }

  initChannelFilter() {
    this.channelMenuItems = [];

    this.impressionStatistics.forEach((statistic) => {
      statistic.ChannelStatistics.forEach((channel) => {
        let index = this.channelMenuItems.findIndex(
          (menuItem) =>
            menuItem.label.toLocaleLowerCase() ===
            channel.Value.toLocaleLowerCase()
        );

        if (index === -1) {
          const channelName = channel.Value;
          this.channelMenuItems.push({
            label: channelName,
            value: channelName.toUpperCase(),
            icon: channelName.toLowerCase() + "Logo",
            isSelected: true,
          });
        }
      });
    });
  }

  initTimeFilter(): void {
    if (this.activateFilter) {
      this.timeMenuItems = [
        {
          label: this.translate.instant("Days"),
          value: "0",
          isSelected: true,
        },
        {
          label: this.translate.instant("Weeks"),
          value: "1",
          isSelected: false,
        },
        {
          label: this.translate.instant("Months"),
          value: "2",
          isSelected: false,
        },
      ];
    } else {
      this.timeMenuItems = [
        {
          label: this.translate.instant("Days"),
          value: "0",
          isSelected: true,
        },
      ];
    }
  }

  selectTimeMenu(menuItem: SelectMenu) {
    this.timeMenuItems.forEach((item, i) => {
      if (item.value === menuItem.value) {
        this.timeMenuItems[i].isSelected = true;
        this.setImpressionsType.emit(parseInt(this.timeMenuItems[i].value));
      } else {
        this.timeMenuItems[i].isSelected = false;
      }
    });

    this.setData();
  }

  selectChannelMenu(menuItem: SelectMenu) {
    this.channelMenuItems.forEach((item, i) => {
      if (item.value === menuItem.value) {
        this.channelMenuItems[i].isSelected = !item.isSelected;
      }
    });

    this.setData();
  }

  setData() {
    this.lineChartData = [];
    this.lineChartLabels.length = 0;
    this.lineChartLabels = [];
    let impressionsData: number[] = [];
    let clicksData: number[] = [];

    this.impressionStatistics.forEach((statistic) => {
      let sumImpressionsValue: number = 0;
      let sumClicksValue: number = 0;
      statistic.ChannelStatistics.forEach((channelStatistic) => {
        if (
          this.isSelectedChannel(channelStatistic.Value) &&
          channelStatistic.Statistics
        ) {
          sumImpressionsValue += channelStatistic.Statistics.Impressions;
          sumClicksValue += channelStatistic.Statistics.Clicks;
        }
      });

      impressionsData.push(sumImpressionsValue);
      clicksData.push(sumClicksValue);

      this.lineChartLabels.push(statistic.Label);
    });

    this.lineChartData.push({
      data: impressionsData,
      lineTension: 0,
      label: this.translate.instant("Ausspielungen"),
    });

    this.lineChartData.push({
      data: clicksData,
      lineTension: 0,
      label: this.translate.instant("Klicks"),
    });
  }

  isSelectedChannel(channelName) {
    let index = this.channelMenuItems.findIndex(
      (menuItem) =>
        menuItem.label.toLocaleLowerCase() === channelName.toLocaleLowerCase()
    );

    return this.channelMenuItems[index].isSelected;
  }
}
